import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import { PageHeader,message,  Button, Input, Table } from 'antd';
import useAuth from '../../hooks/useAuth';


const { Search } = Input;

const CoingeckoList = () => {
    const {auth} = useAuth()
    const columns = [
        {
          title: 'ID',
          dataIndex : 'id',
          key : 'id',
        },

        {
            title : 'Simbolo',
            dataIndex : 'symbol',
            key :'symbol'
        },

        {
            title: 'Nombre',
            dataIndex : 'name',
            key : 'name',
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                <Button type="primary" loading={false} onClick={() => addCoin(record.name, record.id)}>Agregar</Button>

            </>
            )
            },
        }
    ];


    const token = auth.token;


    const [filterCoins, setFilterCoins] = useState([]);

    const [coins, setCoins] = useState([]);

    useEffect(() => {
        getData();
    },[token]);

    const addCoin = (name,id) => {

        let endpoint = `${process.env.REACT_APP_API}/coingecko`;

        axios({
            method:'POST',
            url: endpoint,
            data:  {name:name, id: id},
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            message.success('Coin agregada!');
        })
        .catch(error => {
            if(error.response.data)
                message.error(error.response.data.error);
        })
    }

    const getData = () =>  {
    


        let endpoint = 'https://api.coingecko.com/api/v3/coins/list';

        axios({
            method:'GET',
            url: endpoint
        })
        .then(response => {

            setCoins(response.data);
        })
        .catch(error => {

        })
    }

    const table = () => (
        <Table columns={columns} dataSource={filterCoins.length > 0 ? filterCoins : coins}  rowKey="id"/>
    );
    
    const onSearch = val => {
        let filtered = coins.filter(coin => coin.name.includes(val) );
        setFilterCoins(filtered);
    };

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Coingecko"
        />
        <Search placeholder="Buscar coin" onSearch={onSearch} enterButton />
        {table()}   
    </Body>
    );
}

export default CoingeckoList;