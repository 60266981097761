import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Body from "../../core/Body";
import axios from "axios";
import { PageHeader, Modal, Button, Input, Table, message } from "antd";
import useAuth from "../../hooks/useAuth";

const { Search } = Input;

const ListUser = () => {
    const { auth } = useAuth();
    const columns = [
        {
            title: "Nombre",
            dataIndex: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Estado",
            dataIndex: "status",
            render: (status) => {
                return status == "active" ? (
                    <span className="dot dot-green"></span>
                ) : (
                    <span className="dot dot-red"></span>
                );
            },
        },
        {
            title: "Rol",
            dataIndex: "role",
        },
        {
            title: "Cuentas",
            key: "cuentas",
            render: (record) => {
                return (
                    <>
                        {accounts.map((el, index) => {
                            return (
                                <Link
                                    key={"btn_" + index}
                                    to={`/admin/invertion/${record._id}/list/${el._id}`}
                                >
                                    <Button>{el.name}</Button>
                                </Link>
                            );
                        })}
                    </>
                );
            },
        },
        {
            title: "Acciones",
            dataIndex: "",
            key: "x",
            render: (record) => {
                return (
                    <>
                        <Link
                            style={{ marginLeft: "20px" }}
                            to={`/admin/user/edit/${record._id}`}
                        >
                            Editar
                        </Link>
                        <Button
                            type="link"
                            style={{ marginLeft: "15px" }}
                            onClick={() => deleteRow(record._id, record.name)}
                        >
                            Eliminar
                        </Button>
                    </>
                );
            },
        },
    ];

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 20,
        },
    });

    const [loading, setLoading] = useState(false);

    const [accounts, setAccounts] = useState([]);

    const [deleteId, setDeleteId] = useState("");

    const [showModal, setShowModal] = useState(false);

    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const [nameConfirm, setNameConfirm] = useState("");

    const handleCancel = (e) => {
        setShowModal(false);
    };

    const deleteRow = (id, username) => {
        setDeleteId(id);
        setNameConfirm(username);
        setShowModal(true);
    };

    const confirmDeleteChange = (ev) => {
        if (nameConfirm === ev.target.value) {
            setDeleteDisabled(false);
        }
    };

    const [data, setData] = useState([]);

    const token = auth.token;

    useEffect(() => {
        getAccounts();
    }, []);

    useEffect(() => {
        getData();
    }, [accounts, tableParams.pagination.current]);

    const getAccounts = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/account`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.data.length > 0) {
                    setAccounts(response.data);
                }
            })
            .catch((error) => {});
    };

    const handleOk = (e) => {
        axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_API}/user/${deleteId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setShowModal(false);
                setData(data.filter((el) => el._id !== deleteId));
            })
            .catch((error) => {});
    };

    const getData = (username) => {
        let endpoint = username
            ? `${process.env.REACT_APP_API}/user/search/${username}`
            : `${process.env.REACT_APP_API}/user`;

        setLoading(true);

        axios({
            method: "GET",
            url: endpoint,
            params: {
                page: tableParams.pagination.current,
                limit: tableParams.pagination.pageSize,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.data) {
                    const { users, totalPages } = response.data;

                    setData(users);
                    setLoading(false);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: totalPages,
                        },
                    });
                }
            })
            .catch((error) => {
                message.error("Error retrieving users");
            });
    };
    const importMail = () => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/mailchimp`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                message.success("Emails importados con exito.");
            })
            .catch((error) => {
                message.error("Error importando a mailchimp");
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const table = () => (
        <Table
            loading={loading}
            rowKey="_id"
            columns={columns}
            dataSource={data}
            expandable={{
                expandedRowRender: (record) => (
                    <>
                        {accounts.map((el, index) => {
                            return (
                                <Link
                                    key={"btn_" + index}
                                    to={`/admin/invertion/${record._id}/list/${el._id}`}
                                >
                                    <Button>{el.name}</Button>
                                </Link>
                            );
                        })}
                    </>
                ),
                rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
        />
    );

    return (
        <Body>
            <PageHeader
                className="site-page-header"
                onBack={() => console.log("go-back")}
                title="Todos los usuarios"
            />
            <Modal
                title="Estas seguro que deseas eliminar esta usuario?"
                visible={showModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Eliminar usuario"
                okButtonProps={{ danger: true, disabled: deleteDisabled }}
            >
                <p>Estas eliminando un usuario</p>
                <p>
                    Por favor ingresa el nombre del usuario ({nameConfirm}) para
                    confirmar la acción
                </p>
                <Input
                    onChange={confirmDeleteChange}
                    placeholder="Cantidad de shares"
                />
            </Modal>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Search
                    style={{ width: 400, marginBottom: "20px" }}
                    placeholder="Buscar usuarios"
                    onSearch={(value) => getData(value)}
                    enterButton
                />
                <Button type="primary" onClick={importMail}>
                    Mailchimp import
                </Button>
            </div>
            {table()}
        </Body>
    );
};

export default ListUser;
