import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './assets/styles/antd.css';
import './assets/styles/app.scss';
import GenericNotFound from './core/GenericNotFound';
import Unauthorized from './auth/Unauthorized';
import Signin from './auth/Signin';
import Activate from './auth/Activate';
import Forgot from './auth/Forgot'
import Reset from './auth/Reset'
import { AuthProvider } from './context/AuthProvider';
import AdminRoutes from './routes/AdminRoutes';
import SubscriberRoutes from './routes/SubscriberRoutes';


const ClientRoutes = () => {
    return (
        <AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Signin />} />
                <Route exact path="/recuperar-cuenta" element={<Forgot />} />
                <Route exact path="/recuperar-cuenta/:token" element={<Reset />}  />
                <Route path="/auth/activate/:token" element={<Activate />} />
                <Route path="/login" element={<Signin />} />
                <Route path="/unauthorized" element={<Unauthorized />} />

                {SubscriberRoutes()}
                {AdminRoutes()}
                
                <Route path="*" element={<GenericNotFound/>} />
            </Routes>
        </BrowserRouter>
        </AuthProvider>
    )
}


export default ClientRoutes;