import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { Col, Statistic, Row } from "antd";
import useAuth from "../../hooks/useAuth";

const InvertionStats = ({ stats, account }) => {
	const { auth } = useAuth();

	const [labels, setLabels] = useState({
		shareValue: "Valor de Unit",
		shares: "Unit",
	});
	useEffect(() => {
		if (account.name === "Custodia") {
			setLabels({
				shareValue: "Precio Bitcoin",
				shares: "Cantidad Bitcoin",
			});
		}
		if (account.name === "Maquina I") {
			setLabels({ shareValue: "Valor de Unit", shares: "Cantidad Unit" });
		}
		if (account.name === "USDT(stable coin)") {
			setLabels({ shareValue: "", shares: "Cantidad" });
		}
	}, [account]);
	return (
		<>
			<Row gutter={48} className="stat-row">
				<Col>
					<Statistic
						className="big-statistic"
						title="Valor Actual"
						prefix="$"
						value={stats.valuation}
						precision={2}
					/>
				</Col>

				{(auth.user.role === "admin" ||
					account.name === "Custodia") && (
					<Col>
						<Statistic
							title={labels.shares}
							value={stats.sharesTotal}
						/>
					</Col>
				)}

				{(auth.user.role === "admin" ||
					account.name === "Maquina I") && (
					<Col>
						<Statistic
							title={
								account.name === "USDT(stable coin)"
									? "Depositados"
									: "Suscripto"
							}
							prefix="$"
							value={stats.subscription}
							precision={2}
						/>
					</Col>
				)}

				{account.name === "Maquina I" && (
					<Col>
						<div className="profit-wrap">
							<Statistic
								title="Profit"
								prefix="$"
								suffix={
									Math.sign(stats.percent) === 1
										? "+" + stats.percent + "%"
										: stats.percent + "%"
								}
								value={stats.profit}
								precision={2}
							/>
						</div>
					</Col>
				)}

				{account.name !== "Maquina I LTD" &&
					account.name !== "USDT(stable coin)" && (
						<Col>
							<Statistic
								title={labels.shareValue}
								prefix="$"
								value={stats.accountValue}
								precision={2}
							/>
						</Col>
					)}
			</Row>

			<Row
				justify="end"
				style={{
					borderTop: "1px  solid #ccc",
					marginTop: "1rem",
					padding: ".5rem 0",
				}}
			>
				<div
					style={{
						color: "#666",
						fontSize: "12px",
						textAlign: "right",
					}}
				>
					Valor al{" "}
					<Moment format="DD/MM/YYYY">{stats.dateAccountVal}</Moment>
				</div>
			</Row>
		</>
	);
};

export default InvertionStats;
