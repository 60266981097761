import React from 'react';
import Body from '../core/Body';
import { Result, Button } from 'antd';

const Unauthorized = () => {

    return (
    <Body>
        <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={<Button href="/login">Back Home</Button>}
        />
    </Body>
    );
}

export default Unauthorized;