import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    isMobile
  } from "react-device-detect";
import { PieChart,Legend, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import useAuth from '../../hooks/useAuth';


const COLORS = ['#00aed8', '#001d33', '#8f8f8e','#deeaef','#466e77','#e7e7e6','#f6f6f5'];

const PerfomancePie = ({parentCallback}) => {

    const {auth} = useAuth()
    
    const [data, setData] = useState([]);

    const token = auth.token;


    useEffect(() => {
        getData();
    },[]);

    const getData = () =>  {
    


        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/portfolio/last/record`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.data.percents.length > 0){
                let sorted = response.data.percents.sort(function(a, b) { return a.value < b.value ? 1 : -1});
                setData(sorted);
                parentCallback(response.data.date);
            }
        })
        .catch(error => {
          
        })
    }


    
    const renderLegendLabel = () => {
        return (
            <ul style={{listStyle:'none'}}>
              {
                data.map((entry, index) => (
                  <li key={`item-${index}`} style={{color:'#555',margin:'10px 0'}}>
                    <span style={{width:'80px',height:'6px',display:'inline-block',marginRight:'6px',backgroundColor:COLORS[index]}}></span>
                    {entry.name} <b>{entry.value}%</b>
                  </li>
                ))
              }
            </ul>
          );
    }

    return (
    <div style={{width:isMobile ? '100%' : '100%', height:isMobile ? 550 : 500}}>
        <ResponsiveContainer>
            <PieChart>
                <Pie 
                dataKey="value" 
                isAnimationActive={false} 
                data={data} 
                outerRadius={isMobile ? 100 : 160} 
                cx={isMobile ? -70 : 300}
                cy={isMobile ? 100 : 200}
                fill="#8884d8" >
                {
                data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                ))
                }
            </Pie>
            <Tooltip />
            <Legend verticalAlign={isMobile ? "bottom" : "middle"} align="left" layout="vertical" content={renderLegendLabel} margin={{top:10}}/>
        </PieChart>  
    </ResponsiveContainer> 
   </div>
    );
}

export default PerfomancePie;