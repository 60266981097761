import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import AddPortfolioLog  from './AddPortfolioLog';
import { PageHeader, Divider, Table, Button } from 'antd';
import Moment from 'react-moment';
import useAuth from '../../hooks/useAuth';

const PortfolioLog = () => {

    const {auth} = useAuth()
    let columnsBase = [
        {
          title: 'Date',
          dataIndex : ['date'],
          key : ['date'],
          render:(text)=><Moment format="DD/MM/YYYY">{text}</Moment>
        },

        {
            title: 'Acciones',
            key:'deleteBtn',
            render: (record) => {
            return (
            <>
                <Button style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id)}>Eliminar</Button>
            </>
            )
            },
        }
    ];

    const [columns, setColumns] = useState([]);

    const [data, setData] = useState([]);

    const token = auth.token;


    useEffect(() => {
        getData();
        getCategories();
    },[]);


    const getCategories = () =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/portfoliocategory`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            let catColumnstemp = [];
            response.data.forEach(el => {
                catColumnstemp.push(
                    {
                        title : el.name,
                        dataIndex : el.name,
                    },                   
                );
            });
            setColumns(merge(columnsBase,catColumnstemp,1));
        })
        .catch(error => {
   
        })
    }

    const merge = (a, b, i=0) => {
        return a.slice(0, i).concat(b, a.slice(i));
    }


    const getData = () =>  {


        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/portfolio`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            let newData = [];

            response.data.forEach(el => {
                let row = {
                    date: el.date,
                    _id: el._id
                }
                el.portfolioValues.forEach((val,index) => {
                    if(val.portfolioCategory){
                        if(val.portfolioCategory.name){
                            row[val.portfolioCategory.name] = val.value;
                        }else{
                            row['Undefined'] = val.value;
                        }
                    }

                });
                newData.push(row);
            });

            setData(newData);
        })
        .catch(error => {
        
        })
    }

    const deleteRow = (id) => {
        axios({
            method:'DELETE',
            url: `${process.env.REACT_APP_API}/portfolio/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setData(data.filter((el) => el._id === id ));
        })
        .catch(error => {
           
        })
    }

    const addRowLog = (log) => {
        let row = {
            date: log.date,
            _id: log._id
        }
        log.portfolioValues.forEach(val => {
            row[val.portfolioCategory.name] = val.value;
        });
        setData([row, ...data]);
    }

    const table = () => (
        data && <Table columns={columns} dataSource={data} rowKey="date"/>
    );


    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Portfolio"
        />
        <Divider dashed />
        <AddPortfolioLog addRowLog={addRowLog}/>
        {table()}   
    </Body>
    );
}

export default PortfolioLog;