import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Body from "../../core/Body";
import axios from "axios";
import { Typography, Col, Row, Button, PageHeader, Statistic } from "antd";
import CompositionPie from "../../common/perfomance/CompositionPie";
import useAuth from "../../hooks/useAuth";

const { Title } = Typography;

const AccountDetail = () => {
	const navigate = useNavigate();
	const { auth } = useAuth();

	const token = auth.token;

	const [stats, setStats] = useState({
		percents: [],
		details: [],
	});

	useEffect(() => {
		getStats();
	}, []);

	const getStats = () => {
		axios({
			method: "GET",
			url: `${process.env.REACT_APP_API}/invertion/stats/${auth.user._id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setStats(response.data);
			})
			.catch((error) => {});
	};

	const table = () => (
		<div className="account-table">
			<Row>
				<Col xs={24} md={14}>
					<Title level={4}>Inversiones y depósitos:</Title>
					<Row className="cards__header">
						<Col span={8} offset={1}>
							Cuenta
						</Col>
						<Col span={6}>Precio</Col>
						<Col span={6}>Valor Actual</Col>
					</Row>
					<div className="cards">
						{stats.details.map((el, index) => (
							<Row key={index}>
								<Col
									xs={6}
									md={7}
									offset={1}
									className={
										index === 0
											? "card__label"
											: "card__label"
									}
								>
									{el.label}
								</Col>
								<Col xs={7} md={7}>
									<Statistic
										precision={2}
										prefix="$"
										value={el.price}
									/>
								</Col>
								<Col xs={7} md={7}>
									<Statistic
										precision={2}
										prefix="$"
										value={el.valuation}
									/>
								</Col>
								<Col xs={2} md={2}>
									{el.accountID && (
										<Link
											to={`/private/detail/${auth.user._id}/invertion/list/${el.accountID}`}
										>
											<Button
												className="rounded-btn"
												type="primary"
											>
												+
											</Button>
										</Link>
									)}
								</Col>
							</Row>
						))}
					</div>
					<Row className="total__block">
						<Col
							xs={{ span: 12, offset: 0 }}
							md={{ span: 4, offset: 1 }}
							className="total__label"
						>
							Total
						</Col>
						<Col
							xs={{ span: 12, offset: 0 }}
							md={{ span: 8, offset: 7 }}
							align="end"
						>
							<Statistic
								precision={2}
								prefix="$"
								value={stats.total}
							/>
						</Col>
					</Row>
				</Col>
				<Col xs={{ span: 24, offset: 0 }} md={{ span: 7, offset: 2 }}>
					<Title className="mt-mobile" level={4}>
						Así está compuesta tu cartera
					</Title>
					<CompositionPie
						radius={90}
						cx={150}
						cy={150}
						percents={stats.percents}
					/>
				</Col>
			</Row>
		</div>
	);

	return (
		<Body>
			<PageHeader
				className="site-page-header"
				onBack={() => navigate(-1)}
				title="Detalle de cuenta"
			/>
			<div className="body-inner">{table()}</div>
		</Body>
	);
};

export default AccountDetail;
