import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Body  from '../../core/Body';
import axios from 'axios';


import { PageHeader, Table,Button,Form,Modal,Input} from 'antd';
import useAuth from '../../hooks/useAuth';

const ListAccount = () => {

    const {auth} = useAuth()

    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
          },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                <Link style={{marginLeft:'15px'}} to={`/admin/account/edit/${record._id}`}><Button>Editar</Button></Link>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id, record.name)}>Eliminar</Button>
            </>
            )}
        },
    ];

    const [form] = Form.useForm();

    const [showModal, setShowModal] = useState(false);

    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const [deleteId, setDeleteId] = useState('');

    const [confirmName, setConfirmName] = useState('');

    const [data, setData] = useState([]);

    const token = auth.token;


    useEffect(() => {
        getData();
    },[token]);

    const getData = () =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/account`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setData(response.data);
        })
        .catch(error => {
    
        })
    }

    const table = () => (
        <Table columns={columns} dataSource={data}  rowKey="_id"/>
    );

    const deleteRow = (id, name) => {
        setDeleteId(id);
        setConfirmName(name);
        setShowModal(true);
    }

    const confirmDeleteChange = ev => {

        if(confirmName === ev.target.value){
            setDeleteDisabled(false);
        }
    }

    const handleCancel = e => {

        form.resetFields();
        setShowModal(false);
    };

    const handleOk = e => {


        if(deleteId){
            axios({
                method:'DELETE',
                url: `${process.env.REACT_APP_API}/account/${deleteId}`,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {

                setData(data.filter((el) => el._id !== deleteId ));
                setShowModal(false);
                setConfirmName('');
                setDeleteId('');
                form.resetFields();
            })
            .catch(error => {
  
            })  
        }

    };

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Todas las cuentas"
        />
                <Modal
          title="Estas seguro que deseas eliminar esta cuenta?"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Eliminar cuenta"
          okButtonProps={{ danger:true, disabled: deleteDisabled }}
        >
          <p>
            Estas eliminando una cuenta y vas a armar un quilombo
          </p>
          <p>
          Por favor escribe el nombre de la cuenta ({confirmName}) para confirmar la acción
          </p>
          <Form form={form}>
            <Form.Item name="confirmChange">
                <Input onChange={confirmDeleteChange} placeholder="Nombre de cuenta" />
            </Form.Item>
          </Form>
        </Modal>
        {table()}   
    </Body>
    );
}

export default ListAccount;