import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Body from '../../core/Body';
import axios from 'axios';
import Moment from 'react-moment';
import { PageHeader, Form , Modal,  Button, Select,  Tag,  Input, Table } from 'antd';
import {DeleteOutlined,EditOutlined,UnorderedListOutlined} from '@ant-design/icons';
import useAuth from '../../hooks/useAuth';
const { Option } = Select;

const ListInvertion = () => {

    const {auth} = useAuth();

    const columns = [
        {
          title: 'Fecha',
          dataIndex : ['movement', 'date'],
          key : ['movement', 'date'],
          render:(text)=>((text && (<Moment format="DD/MM/YYYY">{text}</Moment>) || 'No hay fecha'))
        },
        {
            title : 'Inversor',
            dataIndex : ['investor', 'name'],
            key : ['investor', 'name']
        },
        {
            title : 'Cuenta',
            dataIndex : ['account' , 'name'],
            key : ['account' , 'name']
        },
        {
            title : 'Cantidad Unit',
            dataIndex : ['movement', 'amount'],
            key : ['movement', 'amount'],
            render : record => (
                <span>{record}</span>
            )
        },
        {
            title: 'Valor de Unit',
            dataIndex : ['movement', 'amountConvertion'],
            key : ['movement', 'amountConvertion'],
            render : record => (
                <Tag color='geekblue' key={record}>{record}</Tag>
            )
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                {record.investor && (<><Link to={`/admin/movement/invertion/${record.investor._id}/${record._id}/${record.account._id}`}><Button><UnorderedListOutlined/></Button></Link>
                <Link style={{marginLeft:'15px'}} to={`/admin/invertion/edit/${record._id}`}><Button><EditOutlined/></Button></Link>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id, record.investor.name)}><DeleteOutlined/></Button></>)}
            </>
            )
            },
        }
    ];

    const [showModal, setShowModal] = useState(false);

    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const [deleteId, setDeleteId] = useState('');

    const [confirmName, setConfirmName] = useState('');

    const [data, setData] = useState([]);

    const [form] = Form.useForm();

    const token = auth.token;

    const [investor, setInvestor] = useState('');

    const [investorOptions, setInvestorOptions] = useState([]);

    useEffect(() => {
        getData();
    },[investor]);

    const getData = () =>  {
    
        let endpoint = (investor) ? `${process.env.REACT_APP_API}/invertion/byuser/${investor}` : `${process.env.REACT_APP_API}/invertion`

        axios({
            method:'GET',
            url: endpoint,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setData(response.data);
        })
        .catch(error => {
        
        })
    }

    const table = () => (
        <Table columns={columns} dataSource={data}  rowKey="_id"/>
    );

    const handleOk = e => {


        if(deleteId){
            axios({
                method:'DELETE',
                url: `${process.env.REACT_APP_API}/invertion/${deleteId}`,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {

                setData(data.filter((el) => el._id !== deleteId ));
                setShowModal(false);
                setConfirmName('');
                setDeleteId('');
                form.resetFields();
            })
            .catch(error => {
  
            })  
        }

    };

    const handleCancel = e => {

        form.resetFields();
        setShowModal(false);
    };

    const deleteRow = (id, investorName) => {
        setDeleteId(id);
        setConfirmName(investorName);
        setShowModal(true);
    }

    const confirmDeleteChange = ev => {

        if(confirmName === ev.target.value){
            setDeleteDisabled(false);
        }
    }

    const listInvestorOptions = () => {
        return investorOptions.map(d => <Option key={d._id}>{d.name}</Option>);
    }

    const handleInvestorSearch = value => {
        if (value) {
          searchOptions(value);
        } 
    };

    const searchOptions = (keyword) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/user/search/${keyword}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setInvestorOptions(response.data);          
        })
        .catch(error => {

        });
    }

    const handleInvestorChange = (value) =>{
        setInvestor(value);
    }



    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Todas las inversiones"
        />
        <Modal
          title="Estas seguro que deseas eliminar esta inversión?"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Eliminar inversión"
          okButtonProps={{ danger:true, disabled: deleteDisabled }}
        >
          <p>
            Estas eliminando una inversión y <b>TODOS</b> sus movimientos
          </p>
          <p>
          Por favor escribe el nombre del inversor ({confirmName}) para confirmar la acción
          </p>
          <Form form={form}>
            <Form.Item name="confirmChange">
                <Input onChange={confirmDeleteChange} placeholder="Nombre del Inversor" />
            </Form.Item>
          </Form>
        </Modal>
        <div style={{display:'flex', justifyContent:'space-between' ,marginBottom:'20px' }}>
        <Select
            style={{width:'300px'}}
            showSearch
            value={investor}
            allowClear={true}
            placeholder="Inversor"
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={handleInvestorSearch}
            onChange={handleInvestorChange}
            notFoundContent={null}
        >
            {listInvestorOptions()}
        </Select>
        <Link to={`/admin/invertion/add/`}><Button type="primary">Nueva Inversión</Button></Link>
        </div>

        {table()}   
    </Body>
    );
}

export default ListInvertion;