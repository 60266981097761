import React, {useState, useEffect} from 'react';
import axios from 'axios';

import moment from 'moment';

import { Form, DatePicker,  Button, message, Input } from 'antd';
import useAuth from '../../hooks/useAuth';

const AddFundLog = ({addRowLog, edit}) => {
    const {auth} = useAuth()
    const [values, setValues] = useState({
        date: '',
        btc: '',
        mvis: '',
        maquina: '',
        btcVal: '',
        buttonText: 'Crear registro'
    });

    const [form] = Form.useForm();

    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const { buttonText} = values;


    useEffect(() => {

        if(Object.keys(edit).length !== 0){
            setValues({...values, buttonText:'Actualizar registro'});
            form.resetFields(['date','mvis','btc','btcVal','maquina']);
            form.setFieldsValue({
                date:moment(edit.date),
                mvis:edit.mvis,
                btc:edit.btc,
                maquina:edit.maquina,
                btcVal:edit.btcVal
            });
        }

    },[edit]);

    const handleSubmit = values =>  {
        

        setValues({...values,buttonText: 'Guardando...'});
        setLoading(true);

        let method = 'POST';

        let endpoint = `${process.env.REACT_APP_API}/fund`;
        
        if(edit._id){
            method = 'PUT';
            endpoint += `/${edit._id}`;
        }


        axios({
            method:method,
            url: endpoint,
            data:  values,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setValues({...values, buttonText:'Crear registro'});

            if(!edit._id){
                addRowLog(response.data.fund);
                message.success('Registro creado');
            }else{
                message.success('Registro actualizado');
            }

        })
        .catch(error => {
            message.error('Error al crear un registro');
            setValues({...values, buttonText:'Crear registro'});
            setLoading(false);
        })
    }

    const pasteChange = e => {
            const pasted = e.clipboardData.getData('Text');
            const toDivide = pasted.split('	');
            form.resetFields(['mvis','btc','btcVal','maquina']);
            form.setFieldsValue({
                mvis:toDivide[0],
                btc:toDivide[1],
                maquina:toDivide[2],
                btcVal:toDivide[3]
            });

    }

    const formOutput = () => (
        <Form form={form} layout="inline" onFinish={handleSubmit}>
            
            <Form.Item label="Fecha" name="date">
                <DatePicker />
            </Form.Item>

            <Form.Item label="MVIS10" name="mvis">
                <Input   onPaste={pasteChange} placeholder="MVIS10" />
            </Form.Item>

            <Form.Item label="Bitcoin" name="btc">
                <Input placeholder="Bitcoin" />
            </Form.Item>

            <Form.Item label="Maquina" name="maquina">
                <Input  placeholder="Maquina" />
            </Form.Item>

            <Form.Item label="Bitcoin Val" name="btcVal">
                <Input   placeholder="Bitcoin Val" />
            </Form.Item>

            <Form.Item>
                <Button loading={loading} htmlType="submit" >{buttonText}</Button>
            </Form.Item>
        </Form>
    );

    return (
        <>
            {formOutput()}
        </>
    );
}

export default AddFundLog;