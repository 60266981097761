import React from 'react';
import { PieChart,Legend, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import {
  isMobile
} from "react-device-detect";

const COLORS = ['#036088', '#00aed8', '#deeaef','#2aff00','#353393','#e043b9','#95c6c6'];
  

const CompositionPie = ({percents,cy,radius}) => {

  const renderLegend = () => {
    return (
      <ul style={{listStyle:'none'}}>
        {
          percents.map((entry, index) => (
            <li key={`item-${index}`}>
              <span style={{width:'45px',height:'6px',display:'inline-block',marginRight:'6px',backgroundColor:COLORS[index]}}></span>
              {entry.name} <b>{entry.value}%</b>
            </li>
          ))
        }
      </ul>
    );
  }


  return (
    <>
    <ResponsiveContainer width='100%' height={isMobile ? 250 : 400}>
    <PieChart>
      <Pie dataKey="value" isAnimationActive={false} cy={isMobile ? 85 : cy} cx={isMobile ? 150 : 150} data={percents} outerRadius={isMobile ? 60 : radius} fill="#8884d8" label={false}>
        {
        percents.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index]}/>
        ))
      }
      </Pie>
      <Tooltip />
      <Legend verticalAlign="bottom" content={renderLegend} />
    </PieChart>
    </ResponsiveContainer>
</>
  );
}

export default CompositionPie;



