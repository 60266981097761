import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import logo from "../assets/images/logoblack.png";
import { Form, message, Button, Input, Tooltip } from "antd";
import {
    EyeInvisibleOutlined,
    LockOutlined,
    EyeTwoTone,
    InfoCircleOutlined,
    UserOutlined,
} from "@ant-design/icons";
import ValidateTwoFactor from "../core/ValidateOTP";
import { authenticate } from "./helpers";

const Signin = () => {
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();

    const location = useLocation();

    const [values, setValues] = useState({
        email: "",
        password: "",
        buttonText: "Ingresar",
    });

    const [userId, setUserId] = useState("");

    const [showValidate2F, setShowValidate2F] = useState(false);

    const { email, password, buttonText } = values;

    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };

    useEffect(() => {
        if (auth.user) {
            const redirectTo =
                auth && auth?.user?.role === "admin"
                    ? "/admin/dashboard"
                    : "/private/dashboard";
            const from = location.state?.from || redirectTo;
            navigate(from, { replace: true });
        }
    }, [auth]);

    useEffect(() => {
        localStorage.removeItem("_expiredTime");
    }, []);

    const loginSuccess = (response) => {
        const { user, token } = response.data;

        authenticate(response.data, () => {
            setValues({
                ...values,
                email: "",
                password: "",
                buttonText: "Ingresar",
            });
            setAuth({ user, token });
        });
    };

    const clickSubmit = (event) => {
        event.preventDefault();

        setValues({ ...values, buttonText: "Autenticando.." });

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/signin`,
            data: { email, password },
        })
            .then((response) => {
                const { action, id } = response.data;

                if (action === "validate2fa") {
                    setUserId(id);
                    setShowValidate2F(true);
                }

                loginSuccess(response);
            })
            .catch((error) => {
                if (error.response && error.response.data) {
                    message.error(error.response.data.error);
                    setValues({ ...values, buttonText: "Ingresar" });
                }
            });
    };

    const signinForm = () => (
        <Form layout="vertical">
            <Form.Item label="E-mail" name="email">
                <Input
                    onChange={handleChange("email")}
                    value={email}
                    placeholder="Ingresa usuario o correo electrónico"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    suffix={
                        <Tooltip title="El usuario sera proporcionado por el administrador">
                            <InfoCircleOutlined
                                style={{ color: "rgba(0,0,0,.45)" }}
                            />
                        </Tooltip>
                    }
                />
            </Form.Item>
            <Form.Item label="Contraseña" name="password">
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    onChange={handleChange("password")}
                    placeholder="Contraseña"
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                />
            </Form.Item>
            <Button
                type="primary"
                className="btn-black"
                onClick={clickSubmit}
                htmlType="submit"
            >
                {buttonText}
            </Button>
            <Button
                type="link"
                className="link"
                style={{ margin: "0 16px" }}
                href="/recuperar-cuenta"
            >
                Olvidaste la contraseña?
            </Button>
        </Form>
    );

    return (
        <div>
            <div className="login-header">
                <img src={logo} className="logo-signin" alt="logo" />
                <h1>
                    <b>Ingresa</b> a tu cuenta
                </h1>
            </div>
            <div
                style={{
                    padding: "3rem 0",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div>
                    {!showValidate2F && signinForm()}
                    {showValidate2F && (
                        <ValidateTwoFactor
                            callback={loginSuccess}
                            id={userId}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Signin;
