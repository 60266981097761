import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
	LineChart,
	ReferenceLine,
	Line,
	XAxis,
	YAxis,
	ResponsiveContainer,
	CartesianGrid,
	Tooltip,
	Legend,
} from "recharts";
import Moment from "react-moment";
import { ReferenceLabel } from "../../common/ReferenceLineLabel";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { Row, Col, Statistic, Typography, DatePicker, PageHeader } from "antd";
import Body from "../../core/Body";
import useAuth from "../../hooks/useAuth";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const PageShare = () => {
	const navigate = useNavigate();

	const { auth } = useAuth();
	const [data, setData] = useState([]);

	const token = auth.token;

	const [variation, setVariation] = useState({
		maquina: 0,
	});

	const [bigger, setBigger] = useState(0);

	useEffect(() => {
		const yearAgo = moment(Date.now()).subtract(1, "Y");

		getData(yearAgo, moment(Date.now()));
	}, []);

	const getData = (start, end) => {
		axios({
			method: "POST",
			url: `${process.env.REACT_APP_API}/fund/btwdate`,
			data: { start: start, end: end },
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				const funds = response.data.fund.reverse();
				setData(funds);
				setVariation(response.data.variation);
				setBigger(funds[funds.length - 1].maquina);
			})
			.catch((error) => {});
	};

	const rangeOnChange = (dates, dateString) => {
		let start = moment(dateString[0]).utcOffset(0);
		start.set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
		start.toISOString();
		start.format();
		let end = moment(dateString[1]).utcOffset(0);
		end.set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
		end.toISOString();
		end.format();
		getData(start, end);
	};

	return (
		<Body>
			<PageHeader
				className="site-page-header"
				onBack={() => navigate(-1)}
				title="¿Entre qué fechas querés ver el cambio de valor de nuestro Unit?"
			/>

			<Row className="padded-row">
				<Col
					xxl={8}
					xl={8}
					lg={8}
					md={8}
					xs={24}
					align="middle"
					className="bordered mobile-shadow vertical-padding-mobile"
				>
					<Title level={4} className="title-color">
						Elegir Fechas
					</Title>
					<RangePicker
						placeholder={["Desde", "Hasta"]}
						onChange={rangeOnChange}
					/>
					<div style={{ marginTop: "5rem", textAlign: "center" }}>
						<Title level={4} style={{ fontWeight: "700" }}>
							Variación
						</Title>
						<Statistic
							prefix={variation.maquina > 0 ? "+" : ""}
							suffix="%"
							value={variation.maquina}
							precision={2}
							valueStyle={{
								color: "#295876",
								fontSize: "3rem",
								fontWeight: "600",
							}}
						/>
						<div>Valor por defecto: Últimos 365 días</div>
					</div>
				</Col>
				<Col
					xl={16}
					xs={24}
					className="padded-col vertical-padding-mobile"
				>
					{data && (
						<ResponsiveContainer
							width="100%"
							height={isMobile ? 250 : 500}
						>
							<LineChart
								data={data}
								margin={{
									top: 50,
									right: 75,
									left: 0,
									bottom: 0,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis
									axisLine={false}
									style={{ fontSize: "11px" }}
									minTickGap={20}
									padding={{ left: 0, right: 40 }}
									dataKey="date"
									interval="preserveEnd"
									tickFormatter={(tickItem) =>
										moment(tickItem).format("DD/MM/YYYY")
									}
								/>
								<YAxis axisLine={false} width={40} />
								<Tooltip content={CustomTooltip} />
								<Legend />
								<Line
									type="monotone"
									dataKey="maquina"
									name="Maquina Capital I"
									strokeWidth={2}
									stroke="#295876"
									dot={false}
								/>
								<ReferenceLine
									y={bigger}
									label={
										<ReferenceLabel
											position="top"
											value={`Valor: ${bigger.toFixed(
												2
											)}`}
											fill="#fff"
										/>
									}
									stroke="#666"
								/>
							</LineChart>
						</ResponsiveContainer>
					)}
				</Col>
			</Row>
		</Body>
	);
};

const CustomTooltip = ({ active, payload, label }) => {
	if (active) {
		return (
			<div className="custom-tooltip">
				<p className="label">
					{" "}
					{<Moment format="DD/MM/YYYY">{label}</Moment>}{" "}
				</p>
				<p>
					Maquina Capital I: {payload !== null ? payload[0].value : 0}
				</p>
			</div>
		);
	}

	return null;
};

export default PageShare;
