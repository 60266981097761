import React, {useState, useEffect} from 'react';
import Body from '../core/Body';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { Form, Row, Col, Button, message, Input } from 'antd';
import logo from '../assets/images/logoblack.png'
import { useNavigate, useParams } from 'react-router-dom';

const Reset = () => {


    let {token} = useParams();

    let navigate = useNavigate();

    const [values, setValues] = useState({
        name:'',
        newPassword:'',
        tokenInput:'',
        buttonText: 'Cambiar contraseña'
    });

    const {name, newPassword, buttonText} = values;

    useEffect(() => {

        let {name} = jwt.decode(token);

        if(token) {
            setValues({...values, name, tokenInput: token});
        }

    },[token]);



    const handleChange = (event) => {
        setValues({...values, newPassword:event.target.value});
    }

    const clickSubmit = event =>  {
        
        event.preventDefault();

        setValues({...values,buttonText: 'Cargando..'});
        axios({
            method:'PUT',
            url: `${process.env.REACT_APP_API}/reset-password`,
            data: {newPassword, resetPasswordLink: token}
        })
        .then(response => {

            message.success('Contraseña cambiada correctamente');

            navigate('/login');
        })
        .catch(error => {

            message.error(error.response.data.error);

            setValues({...values, buttonText:'Cambiar contraseña'});
        })
    }

    const resetForm = () => (
        <Row>
            <Col xl={{offset:8 , span:8}} xs={{offset:2, span:20}}>
            <h5 className="p-5 text-center">{name}, Ingresa tu nueva contraseña</h5>
            <Form.Item label="Nueva contraseña" >
                <Input.Password required onChange={handleChange} value={newPassword} type="password" placeholder="••••••••••" />
            </Form.Item>
            <Button type="primary" onClick={clickSubmit}>{buttonText}</Button>

            </Col>
        </Row>
    );

    return (
    <>
        <div className="login-header">
            <img src={logo} className="logo-signin" alt="logo"/>
            <h1><b>Recupera</b> tu cuenta</h1>
        </div>
        {resetForm()}
    </>
    );
}

export default Reset;