import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {  Table, Select } from 'antd';

import {
    isMobile
  } from "react-device-detect";
import useAuth from '../../hooks/useAuth';
const { Option } = Select;
const Perfomance = () => {
    const {auth} = useAuth()
    const token = auth.token;


    const columns = [
        {
            title : '',
            dataIndex : 'label',
            render:(text)=>(
                <>
                {text}
                </>
            )
        },
        {
            title: '30D',
            className: 'rend-td rend-td-1 active',
            render:(record)=>(
                <>
                {isMobile && <div className="label-mobile">{record.label}</div>}
                {record.thirtyDays}
                </>
              )
        },
        {
            title : '90D',
            className: 'rend-td rend-td-2',
            render:(record)=>(
                <>
                {isMobile && <div className="label-mobile">{record.label}</div>}
                {record.ninetyDays}
                </>
            )
        },
        {
            title : '365D',
            className: 'rend-td rend-td-3',
            render:(record)=>(
                <>
                {isMobile && <div className="label-mobile">{record.label}</div>}
                {record.yearDays}
                </>
            )
        },
        {
            title : 'YTD',
            className: 'rend-td rend-td-4',
            render:(record)=>(
                <>
                {isMobile && <div className="label-mobile">{record.label}</div>}
                {record.ytd}
                </>
            )
        }
    ];

    const handleChange = (value) => {

        const allTds = document.querySelectorAll('.rend-td');
        allTds.forEach(function(td) {
            td.classList.remove("active");
        });
        const tds = document.querySelectorAll('.rend-td-'+value);
        tds.forEach(function(td) {
            td.classList.add("active");
        });
    }

    const [perfomance, setPerfomance] = useState([]);


    useEffect(() => {
        getPerfomance();
    },[]);

    const getPerfomance = () =>  {
      axios({
          method:'GET',
          url: `${process.env.REACT_APP_API}/fund/perfomance`,
          headers: {
            Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
          setPerfomance(response.data);
      })
      .catch(error => {

      })
    }

    return (
        <>
        {isMobile && (
            <>
            <label>Selecciona un plazo:</label>
            <Select defaultValue="1"  style={{margin:'1rem .5rem',width: '100px' }} onChange={handleChange}>
                <Option value="1">30D</Option>
                <Option value="2">90D</Option>
                <Option value="3">365D</Option>
                <Option value="4">YTD</Option>
            </Select>
            </>
        )}
        <Table pagination={false} className="rendimiento-table" columns={columns} dataSource={perfomance}  rowKey="name"/> 
        </>
    );
}

export default Perfomance;