import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({allowedRole}) => {
    const {auth} = useAuth();
    const location = useLocation();
    return ( allowedRole.includes(auth?.user?.role) ? <Outlet/> : <Navigate to="/login" state={{from: location }} replace />);

}

export default RequireAuth;