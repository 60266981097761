import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import { PageHeader, Divider, Table,  Button } from 'antd';
import useAuth from '../../hooks/useAuth';

const ListPortfolioCategory = () => {
    const {auth} = useAuth()

    const columns = [
        {
          title: 'Nombre',
          dataIndex : 'name',
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id)}>Eliminar</Button>
            </>
            )
            },
        }
    ];



    const [data, setData] = useState([]);

    const token = auth.token;


    useEffect(() => {
        getData();
    },[]);

    const getData = () =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/portfoliocategory`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setData(response.data);
        })
        .catch(error => {

        })
    }

    const deleteRow = (id) => {
        axios({
            method:'DELETE',
            url: `${process.env.REACT_APP_API}/portfoliocategory/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setData(data.filter((el) => el._id !== id ));
        })
        .catch(error => {
           
        })
    }


    const table = () => (
        <Table columns={columns} dataSource={data}  rowKey="_id"/>
    );


    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Categorias de Portfolio"
        />
        <Divider dashed />
        {table()}   
    </Body>
    );
}

export default ListPortfolioCategory;