import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Form, Skeleton, Select, DatePicker, PageHeader, Button, message, Input } from 'antd';
import useAuth from '../../hooks/useAuth';

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const AddInvertion = () => {

    const navigate = useNavigate();
    
    const {auth} = useAuth();

    let {invertion: invertionId} = useParams();

    const [values, setValues] = useState({
        buttonText: 'Crear inversión'
    });

    const [loadingInvertion,setLoadingInvertion] = useState(true);

    const [loadedShare, setLoadedShare] = useState(false);

    const [initialValues, setInitialValues] = useState({
        movement: '',
        user : auth.user._id,
        amount: '',
        date: '',
        status:'active',
        wallet:'',
        investor: '',
        operation: '', 
        amountConvertion : '',
        account: '',
    });

    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const {buttonText} = values;

    const [accountOptions, setAccountOptions] = useState([]);

    const [series, setSeries] = useState([]);

    const [investorOptions, setInvestorOptions] = useState([]);

    const [walletHidden, setWalletHidden] = useState(true);

    const [amountLabel, setAmountLabel] = useState('Cantidad de Shares');

    const [shareValue , setShareValue] = useState({});

    const [form] = Form.useForm();

    const [title,setTitle] = useState('Nueva');

    useEffect(() => {
        if(invertionId){
            setTitle('Editar');
            getInvertion();
        }else{
            getOperation();
            setLoadingInvertion(false);
        }
    },[invertionId]);


    useEffect(() => {
        getAccounts();
        getSeries();
    },[token]);

    const getSeries = () =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/fundseries`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setSeries(response.data);

        })
        .catch(error => {
   
        })
    }

    const getInvertion = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/invertion/${invertionId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setValues({...values,buttonText: 'Editar'});
            setLoadingInvertion(false);
            const {date, account, user, investor, movement,status, wallet} = response.data;
            form.setFieldsValue({
                user : user,
                date: moment(date),
                account: account,
                investor: investor,
                status:status,
                wallet: wallet,
                operation: movement.operation._id,
                movemement: movement._id,
                amount: movement.amount,
                amountConvertion: movement.amountConvertion
            });
        })
        .catch(error => {
            
        });  
    };

    const getShareValue = (date) => {
        const start = date.set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0}).format('YYYY-MM-DDTHH:mm');
        const end = date.set({'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 0}).format('YYYY-MM-DDTHH:mm');
        axios({
            method: 'POST',
            data:{start: start, end: end},
            url: `${process.env.REACT_APP_API}/fund/bydate`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setShareValue(response.data);
            setLoadedShare(true);

        })
        .catch(error => {
          
        });  
    };

    const getOperation = () => {
        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/operation/byname/Subscription`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            form.setFieldsValue({operation: response.data._id});
        })
        .catch(error => {
            
        })       
    };

    const getAccounts = () => {
        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/account`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setAccountOptions(response.data);
        })
        .catch(error => {

        })       
    };



    const handleInvestorSearch = value => {

        if (value) {
          searchOptions(value);
        } else {
          setInvestorOptions([])
        }

    };


    const listAccountOptions = () => {
        return accountOptions.map(d => <Option key={d._id} >{d.name}</Option>);
    }

    const listInvestorOptions = () => {
        return investorOptions.map(d => <Option key={d._id} >{d.name}</Option>);
    }

    const searchOptions = (keyword) => {

        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/user/search/${keyword}`,
            params:{
                role: ['subscriber','guest']
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setInvestorOptions(response.data.users);
            
        })
        .catch(error => {
           
        });
    }

    const handleSubmit = values =>  {

        setValues({...values,buttonText: 'Guardando...'});


        setLoading(true);

        let method = 'POST';
        let endpoint = `${process.env.REACT_APP_API}/invertion`;
        if(invertionId){
            method = 'PUT';
            endpoint += '/' + invertionId;
        }
        
        axios({
            method: method,
            url: endpoint,
            data:  values,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setValues({...values, buttonText:'Crear inversión'});
            message.success('Inversión guardada');
            navigate('/admin/invertion/list');
        })
        .catch(error => {
            message.error('Error al guardar la inversión');
            setValues({...values, buttonText:'Crear inversión'});
            setLoading(false);
        });
    }

    const dateChange = (value) => {
        getShareValue(value);
    }

    const handleFormValuesChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];

        if(fieldName === "account"){
            const value = changedValues[fieldName].label;
            if(value === 'Custodia'){
                setAmountLabel('Cantidad de Bitcoin');
                setWalletHidden(false);
            }else{
                setAmountLabel('Cantidad Unit');
                setWalletHidden(true);
            }

        } 
    }

    const setAmountConvertion = (val) => {
        form.setFieldsValue({amountConvertion: val});
    }

    const getForm = () => (
        <Form {...layout} form={form}  onValuesChange={handleFormValuesChange} initialValues={initialValues} onFinish={handleSubmit} layout="vertical">
            <Form.Item  label="Usuario"  name="user" hidden>
                <Input />
            </Form.Item>  

            <Form.Item  label="Operación"  name="operation" hidden>
                <Input />
            </Form.Item>  

            <Form.Item label="Fecha" name="date">
                <DatePicker onChange={dateChange} />
            </Form.Item>

            <Form.Item label="Inversor" name="investor">
                <Select
                    showSearch
                    placeholder="Inversor"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleInvestorSearch}
                    notFoundContent={null}
                >
                    {listInvestorOptions()}
                </Select>
            </Form.Item>


            <Form.Item label="Estado" name="status">
                <Select>
                    <Option value="active">Active</Option>
                    <Option value="inactive">Inactive</Option>
                </Select>
            </Form.Item>
            
            <Form.Item label="Cuenta" name="account">
                <Select value={{key:"none"}} labelInValue>
                    <Option key="none">Ninguna</Option>
                    {accountOptions && listAccountOptions()}
                </Select>
            </Form.Item>

            <Form.Item label="Tipo de Serie" name="serieType">
                <Select>
                    { series.map(el => <Option value={el.name}>{el.name}</Option>) }
                </Select>
            </Form.Item>

            {loadedShare && (
                <>
                    <Button onClick={() => setAmountConvertion(shareValue.btcVal)}>Bitcoin: {shareValue.btcVal}</Button>
                    <Button onClick={() => setAmountConvertion(shareValue.maquina)}>Maquina: {shareValue.maquina}</Button>
                </>
            )}
            
            <Form.Item label="Valor de Unit" name="amountConvertion">
                <Input placeholder="Valor de Unit" />
            </Form.Item>

            <Form.Item label="Wallet" name="wallet" hidden={walletHidden}>
                <Input placeholder="Wallet" />
            </Form.Item>

            <Form.Item label={amountLabel} name="amount">
                <Input placeholder={amountLabel} />
            </Form.Item>

            <Button loading={loading} type="primary" htmlType="submit">{buttonText}</Button>
        </Form>
    );

    return (
    <Body>
    <PageHeader
        className="site-page-header"
        onBack={() => console.log('go-back')}
        title={title + " inversión"}
    />

    <Skeleton loading={loadingInvertion} active/>

    {!loadingInvertion && getForm()}
        
    </Body>
    );
}

export default AddInvertion;