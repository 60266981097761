import cookie from 'js-cookie';

// set in cookie
export const setCookie = (key, value) => {
    if(window !== undefined){
        let inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);
        cookie.set(key,value,{
            expires:inThirtyMinutes
        });
    }
};
// remove from cookie
export const removeCookie = (key) => {
    if(window !== undefined){
        cookie.remove(key);
    }
};
// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
    if(window !== undefined){
        return cookie.get(key);
    }
};
// set in localstorage
export const setLocalStorage = (key, value) => {
    if(window !== undefined){
        localStorage.setItem(key, JSON.stringify(value));
    }
};
// remove from localstorage
export const removeLocalStorage = (key) => {
    if(window !== undefined){
        localStorage.removeItem(key);
    }
};
// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (data, next) => {
    setCookie('token', data.token);
    setLocalStorage('user', data.user);
    next();
};
// access user info from localstorage
export const isAuth =  () => {
    if(window !== undefined){
        const cookieChecked = getCookie('token');
        if(cookieChecked){
            if(localStorage.getItem('user') !== 'undefined'){
                return JSON.parse(localStorage.getItem('user'));
            }else{
                return false;
            }
        }
    }  
};

export const signout = (next, timer) => {
    if(timer){
        timer.cleanUp();
    }
    removeCookie('token');
    removeLocalStorage('user');
    next();
}

export const updateUser = (response, next) => {
    if(typeof window !== undefined){
        let auth = JSON.parse(localStorage.getItem('user'));
        auth = response.data.user;
        localStorage.setItem('user', JSON.stringify(auth));
    }
    next();
}