import React, {useState, useEffect} from 'react';
import axios from 'axios';

import moment from 'moment';

import { Form, DatePicker,  Button, message, Input } from 'antd';
import useAuth from '../../hooks/useAuth';

const AddLTDFundLog = ({addRowLog, edit}) => {

    const { auth } = useAuth()

    const [values, setValues] = useState({
        date: '',
        fundSeries:[],
        buttonText: 'Crear registro'
    });

    const [form] = Form.useForm();

    const [series, setSeries] = useState([]);

    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const {date, fundSeries,  buttonText} = values;

    const handleChange = (id) => (event) => {
        let copyValues = {...values};
        const elementIndex = copyValues.fundSeries.findIndex(el => el.fundSerie === id);
        if(elementIndex === -1){
            copyValues.fundSeries.push({
                fundSerie: id,
                value: event.target.value
            })
        }else{
            copyValues.fundSeries[elementIndex].value = event.target.value;
        }

        setValues({...values,fundSeries:copyValues.fundSeries});
    }

    useEffect(() => {

        if(Object.keys(edit).length !== 0){
            setValues({...values, buttonText:'Actualizar registro'});
            form.resetFields(['date']);
            form.setFieldsValue({
                date:moment(edit.date)
            });
        }

    },[edit]);




    useEffect(() => {
        getSeries();
    },[token]);

    const getSeries = () =>  {
    
        setLoading(true);

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/fundseries`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setSeries(response.data);
            let seriesTemp = [];
            response.data.map(el => seriesTemp.push({se:el._id,value:0}));
            setValues({...values,fundSerie:seriesTemp});
        })
        .catch(error => {
            setLoading(false);
        })
    }


    const handleSubmit = values =>  {
        

        setValues({...values,buttonText: 'Guardando...'});
        setLoading(true);

        let method = 'POST';

        let endpoint = `${process.env.REACT_APP_API}/ltdfund`;
        
        if(edit._id){
            method = 'PUT';
            endpoint += `/${edit._id}`;
        }

        axios({
            method:method,
            url: endpoint,
            data:  {date, fundSeries},
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setValues({...values, buttonText:'Crear registro'});

            if(!edit._id){
                let createdfund = response.data.fund;
                createdfund.fundSeries.forEach(val => {
                    createdfund[val.fundSerie.name] = val.value;
                });
                form.resetFields();
                addRowLog(createdfund);
                setValues({date: '', fundSeries:[]});
                
                message.success('Registro creado');
            }else{
                message.success('Registro actualizado');
            }

        })
        .catch(error => {

            message.error('Error al crear un registro');
            setValues({...values, buttonText:'Crear registro'});
            setLoading(false);
        })
    }

    const handleDateChange = date =>{
        setValues({...values, date: date});
    }

    const formOutput = () => (
        <Form form={form} layout="inline" onFinish={handleSubmit}>
            
            <Form.Item label="Fecha" name="date">
                <DatePicker  onChange={handleDateChange}/>
            </Form.Item>

            {
                series.map(el => 
                    <Form.Item label={el.name} key={el._id} name={el._id}>
                        <Input onChange={handleChange(el._id)} placeholder={el.name} />
                    </Form.Item>
                )
            }

            <Form.Item>
                <Button loading={loading} htmlType="submit" >{buttonText}</Button>
            </Form.Item>
        </Form>
    );

    return (
        <>
            {formOutput()}
        </>
    );
}

export default AddLTDFundLog;