import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Body from '../../core/Body';
import axios from 'axios';
import { PageHeader,Button, Table, message} from 'antd';
import useAuth from '../../hooks/useAuth';

const DisableUsers = () => {

    const {auth} = useAuth()

    const columns = [
        {
          title: 'Nombre',
          dataIndex: 'name',
        },
        {
        title: 'Email',
        dataIndex: 'email',
        },
        {
        title: 'Acciones',
        dataIndex: '',
        key: 'x',
        render: (record) => {
        return (
                <>
                <Link style={{marginLeft:'20px'}} to={`/admin/user/edit/${record._id}`}>Editar</Link>
                </>)
            },
        }
    ];

    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 20,
        },
    });

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);

    const token = auth.token;


    const disableUsers = () =>  {
        axios({
            method:'POST',
            url: `${process.env.REACT_APP_API}/user/disable`,
            data:{
                userId: auth.user._id
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            message.success('Usuarios actualizados');
            if(response.data){
                const {users, totalPages} = response.data;
                setData(users);
                setLoading(false);
                setTableParams({
                  ...tableParams,
                    pagination: {
                    ...tableParams.pagination,
                    total: totalPages,
                  },
                });
            }
        })
        .catch(error => {
           message.error('Error actualizando usuarios');
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        });
    };

    const table = () => (

        <Table 
        loading={loading}
        rowKey="_id"
        columns={columns}
        dataSource={data}  
        pagination={tableParams.pagination} 
        onChange={handleTableChange}
       />
    );

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Todos los usuarios"
        />
        <div style={{display:'flex', justifyContent:'space-between'}}>
            <Button type="primary" onClick={disableUsers}>Deshabilitar Usuarios</Button>
        </div>
        {table()}   
    </Body>
    );
}

export default DisableUsers;