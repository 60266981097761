import React from 'react';

import Body from '../../core/Body';
import {PageHeader} from 'antd';
import Evolution from '../../subscriber/dashboard/Evolution';
const PageShare = () => {

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Perfomance de Unit"
        />
        <Evolution/>
    </Body>
    );
}

export default PageShare;