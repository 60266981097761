import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Body from '../../core/Body';
import axios from 'axios';
import Moment from 'react-moment';
import { PageHeader, Form , Modal,  Button,  Tag,  Input, Table } from 'antd';
import useAuth from '../../hooks/useAuth';


const ListInvertion = () => {
    const {auth} = useAuth()
    const {id: investorId} = useParams();

    const columns = [
        {
          title: 'Fecha',
          dataIndex : ['movement', 'date'],
          key : ['movement', 'date'],
          render:(text)=>(text && <Moment format="DD/MM/YYYY">{text}</Moment> || 'No hay fecha')
        },
        {
            title : 'Cuenta',
            dataIndex : ['account' , 'name'],
            key : ['account' , 'name']
        },
        {
            title : 'Cantidad de Shares',
            dataIndex : ['movement', 'amount'],
            key : ['movement', 'amount'],
            render : record => (
                <span>{record}</span>
            )
        },
        {
            title: 'Valor de Unit',
            dataIndex : ['movement', 'amountConvertion'],
            key : ['movement', 'amountConvertion'],
            render : record => (
                <Tag color='geekblue' key={record}>${record}</Tag>
            )
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>  

                <Link to={`/admin/movement/invertion/${record.investor._id}/${record._id}`}><Button>Movimientos</Button></Link>
                <Link style={{marginLeft:'15px'}} to={`/admin/invertion/edit/${record._id}`}><Button>Editar</Button></Link>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id, record.investor.name)}>Eliminar</Button>
            </>
            )
            },
        }
    ];

    const [pageTitle, setPageTitle] = useState('');

    const [showModal, setShowModal] = useState(false);

    const [deleteDisabled, setDeleteDisabled] = useState(true);

    const [deleteId, setDeleteId] = useState('');

    const [confirmName, setConfirmName] = useState('');

    const [data, setData] = useState([]);

    const [form] = Form.useForm();

    const token = auth.token;


    useEffect(() => {
        getData();
    },[investorId]);

    const getData = () =>  {
    
        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/invertion/byuser/${investorId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.data.length > 0){
                setData(response.data);
                setPageTitle('Todas las inversiones de ' + response.data[0].investor.name);
            }

        })
        .catch(error => {
           

        })
    }



    const table = () => (
        <Table columns={columns} dataSource={data}  rowKey="_id"/>
    );


    const handleOk = e => {

        if(deleteId){
            axios({
                method:'DELETE',
                url: `${process.env.REACT_APP_API}/invertion/${deleteId}`,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setData(data.filter((el) => el._id !== deleteId ));
                setShowModal(false);
                setConfirmName('');
                setDeleteId('');
                form.resetFields();
            })
            .catch(error => {
                
            })  
        }

    };

    const handleCancel = e => {
        form.resetFields();
        setShowModal(false);
    };

    const deleteRow = (id, investorName) => {
        setDeleteId(id);
        setConfirmName(investorName);
        setShowModal(true);
    }

    const confirmDeleteChange = ev => {
        if(confirmName === ev.target.value){
            setDeleteDisabled(false);
        }
    }



    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title={pageTitle}
        />
        <Modal
          title="Estas seguro que deseas eliminar esta inversión?"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Eliminar inversión"
          okButtonProps={{ danger:true, disabled: deleteDisabled }}
        >
          <p>
            Estas eliminando una inversión y <b>TODOS</b> sus movimientos
          </p>
          <p>
          Por favor escribe el nombre del inversor ({confirmName}) para confirmar la acción
          </p>
          <Form form={form}>
            <Form.Item name="confirmChange">
                <Input onChange={confirmDeleteChange} placeholder="Nombre del Inversor" />
            </Form.Item>
          </Form>
        </Modal>
        <div style={{display:'flex', justifyContent:'space-between' ,marginBottom:'20px' }}>
        <Link to={`/admin/invertion/add/`}><Button type="primary">Nueva Inversión</Button></Link>
        </div>

        {table()}   
    </Body>
    );
}

export default ListInvertion;