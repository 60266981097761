import React, {useState,useEffect} from 'react';
import axios from 'axios';

import { Form, DatePicker,  Button, message, Input } from 'antd';
import useAuth from '../../hooks/useAuth';

const AddPortfolioLog = ({addRowLog}) => {

    const {auth} = useAuth()

    const [values, setValues] = useState({
        date: '',
        portfolioValues:[],
        buttonText: 'Crear registro'
    });

    const [categories, setCategories] = useState([]);

    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const {date, portfolioValues,  buttonText} = values;

    useEffect(() => {
        getCategories();
    },[token]);

    const getCategories = () =>  {
    
        setLoading(true);

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/portfoliocategory`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setCategories(response.data);
            let portfolioValuesTemp = [];
            response.data.map(el => portfolioValuesTemp.push({portfolioCategory:el._id,value:0}));
            setValues({...values,portfolioValues:portfolioValuesTemp});
        })
        .catch(error => {
            setLoading(false);
        })
    }

    const handleChange = (id) => (event) => {
        let copyValues = {...values};
        const elementIndex = copyValues.portfolioValues.findIndex(el => el.portfolioCategory === id);
        copyValues.portfolioValues[elementIndex].value = event.target.value;
        setValues({...values,portfolioValues:copyValues.portfolioValues});
    }

    const handleDateChange = date =>{
        setValues({...values, date: date});
    }

    const clickSubmit = event =>  {
        
        event.preventDefault();

        setValues({...values,buttonText: 'Guardando...'});
        setLoading(true);

        axios({
            method:'POST',
            url: `${process.env.REACT_APP_API}/portfolio`,
            data:  {date, portfolioValues},
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setValues({...values, buttonText:'Crear registro'});
            message.success('Registro creado');

            addRowLog(response.data.portfolio);
        })
        .catch(error => {
            message.error('Error al crear un registro');
            setValues({...values, buttonText:'Crear registro'});
            setLoading(false);
        })
    }

    const form = () => (
        <Form layout="inline">
            <Form.Item label="Fecha" name="date">
                <DatePicker onChange={handleDateChange} />
            </Form.Item>
            {
                categories.map(el => 
                    <Form.Item label={el.name} key={el._id} name={el._id}>
                        <Input onChange={handleChange(el._id)} placeholder={el.name} />
                    </Form.Item>
                )
            }
            <Form.Item>
                <Button loading={loading} type="primary" onClick={clickSubmit}>{buttonText}</Button>
            </Form.Item>
        </Form>
    );

    return (
        <>
            {form()}
        </>
    );
}

export default AddPortfolioLog;