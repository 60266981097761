import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import AddFundLog  from './AddFundLog';
import { PageHeader, Divider, Table, Tag, Button, Upload, message } from 'antd';
import { UploadOutlined, CheckCircleOutlined ,CloseCircleOutlined} from '@ant-design/icons';
import useAuth from '../../hooks/useAuth';

const FundLog = () => {

    const {auth} = useAuth()

    const columns = [
        {
            title: 'Enabled',
            dataIndex : ['enabled'],
            key : ['enabled'],
            render : record => record === true ? (<CheckCircleOutlined style={{color:'green'}}/>) : <CloseCircleOutlined style={{color:'red'}}/>
        },
        {
          title: 'Date',
          dataIndex : ['date'],
          key : ['date']
        },
        {
            title : 'MVIS10',
            dataIndex : ['mvis'],
            key : ['mvis'],
            render : record => (
                <Tag color='geekblue' key={record}>{record}</Tag>
            )
        },
        {
            title : 'Bitcoin',
            dataIndex : ['btc'],
            key : ['btc'],
            render : record => (
                <Tag color='pink' key={record}>{record}</Tag>
            )
        },
        {
            title : 'Maquina',
            dataIndex : ['maquina'],
            key : ['maquina'],
            render : record => (
                <Tag color='green' key={record}>{record}</Tag>
            )
        },
        {
            title : 'Bitcoin Val',
            dataIndex : ['btcVal'],
            key : ['btcVal'],
            render : record => (
                <Tag color='green' key={record}>{record}</Tag>
            )
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => editRow(record)}>Editar</Button>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id)}>Eliminar</Button>
            </>
            )
            },
        }
    ];

    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 30,
        },
    });

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);

    const token = auth.token;

    const [edit,setEdit] = useState({});

    useEffect(() => {
        getData();
    },[tableParams.pagination.current]);

    const getData = () =>  {

        setLoading(true);

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/fund`,
            params:{
                page: tableParams.pagination.current,
                limit: tableParams.pagination.pageSize
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.data){
                const {funds, totalPages} = response.data;
                setData(funds);
                setLoading(false);
                setTableParams({
                  ...tableParams,
                  pagination: {
                    ...tableParams.pagination,
                    total: totalPages,
                  },
                });
            }
        })
        .catch(error => {
            message.error('Error retrieving funds');
        })
    }

    const deleteRow = (id) => {
        axios({
            method:'DELETE',
            url: `${process.env.REACT_APP_API}/fund/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setData(data.filter((el) => el._id !== id ));
        })
        .catch(error => {
     
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
          pagination,
          filters,
          ...sorter,
        });
    };

    const editRow = (record) => {
        setEdit(record);
    }

    const addRowLog = (log) => {
        setData([log, ...data]);
    }

    const table = () => (
        <Table 
        columns={columns} 
        loading={loading} 
        dataSource={data} 
        rowKey="_id"
        pagination={tableParams.pagination} 
        onChange={handleTableChange}
        />
    );

    const props = {
        name:'excel',
        action: `${process.env.REACT_APP_API}/fund/import`,
        headers: {
            Authorization: `Bearer ${token}`
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Maquina I"
            subTitle="Fondo"
        />
        <Upload {...props}>
            <Button icon={<UploadOutlined />}>Importar Excel</Button>
        </Upload>
        <Divider dashed />
        <AddFundLog addRowLog={addRowLog} edit={edit}/>
        {table()}   
    </Body>
    );
}

export default FundLog;