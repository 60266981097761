import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import { Spin, Row, Col, PageHeader, Pagination } from 'antd';
import Moment from 'react-moment';
import axios from 'axios';

import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

const BlogList = () => {

    const { slug } = useParams();

    const navigate = useNavigate();

    const [postData, setPostData] = useState({total: 0, current:1, posts:[]});

    const [ cat, setCat ] = useState({
        name: 'Informes',
        id: '1,1751'
    })

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getPosts(1);
    },[cat]);

    useEffect(() => {
        if(slug === 'informes'){
            setCat({id: '1,1751', name: 'Informes'});
        }else{
            setCat({id: '1756,26', name: 'Cryptonews'});
        }
    },[slug]);

    const getPosts = (page) =>  {


        axios({
            method:'GET',
            url: `https://www.maquinacapital.com/wp-json/wp/v2/posts?_embed&categories=${cat.id}&categories_exclude=1754&page=${page}`
        })
        .then(response => {
            setLoading(false);
            setPostData({total: response.headers['x-wp-total'], current: page, posts: response.data});
        })
        .catch(error => {
    
        });
    
    }

    const onChange = (pageNumber) => {
        getPosts(pageNumber)
    };

    const showPosts = () => {
        if(postData.posts.length > 0){
            return postData.posts.map(el => <Col xs={24} md={8} key={el.id}>
                    <div className="card-blog" >
                        <a href={el.link} target="_blank" rel="noreferrer">
                        <img width="300" style={{width:'100%'}}src={el._embedded['wp:featuredmedia'][0].media_details.sizes['small-dest']?.source_url} alt="" />
                        <div style={{margin:'10px 0'}}>
                            <Moment format="DD/MM/YYYY">{el.date}</Moment>
                        </div>
                        <h3 dangerouslySetInnerHTML={{__html: el.title.rendered }} ></h3>
                        <div>{el.excerpt2}</div>
                        </a>
                    </div>
                </Col>)
        }else{
            return <div style={{textAlign:'center'}}>No hay links en esta categoria.</div>
        }
    }

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={cat.name}
        />
        <div className="body-inner">
        {isLoading && <Spin indicator={LoadingOutlined} />}
        <Row className="inform-row" gutter={[32,32]}>
            { !isLoading && showPosts() }
        </Row>
        { postData.total !== 0 && <Pagination style={{margin:'30px auto'}} current={postData.current} pageSize={10} total={postData.total} onChange={onChange} /> }
        </div>
    </Body>
    );
}

export default BlogList;