import { Route } from "react-router-dom";
import SubscriberDashboard from '../subscriber/dashboard/SubscriberDashboard';
import SubscriberInvertion from '../subscriber/invertion/ListInvertion';
import SubscriberMovement from '../subscriber/movement/ListMovement';
import AccountDetail from '../subscriber/dashboard/AccountDetail';
import PageSharePrivate from '../subscriber/dashboard/PageShare';
import PageRendimiento from '../subscriber/dashboard/PageRendimiento';
import PagePortfolioPrivate from '../subscriber/dashboard/PagePortfolio';
import BlogList from '../subscriber/blog/BlogList';
import RequireAuth from '../auth/RequireAuth';
import Profile from '../core/Profile';
import Notifications from '../core/Notifications';


export default function SubscriberRoutes(){
    return <Route path="private" element={<RequireAuth allowedRole="admin,subscriber,guest"/>}>
        <Route exact path="dashboard" element={<SubscriberDashboard/>} />
        <Route exact path="account" element={<Profile/>} />
        <Route path= "unit" element={<PageSharePrivate/>}  />
        <Route path= "rendimiento" element={<PageRendimiento/>} />
        <Route path= "chartportfolio" element={<PagePortfolioPrivate/>}/>
        <Route exact path="detail/:id/invertion/list/:account" element={<SubscriberInvertion/>}/>
        <Route exact path= "detail/account-detail" element={<AccountDetail/>}/>
        <Route exact path="movement/invertion/:investor/:invertion/:account" element={<SubscriberMovement/>}/>
        <Route exact path="blog/:slug" element={<BlogList/>}/>
        <Route exact path="notifications/:id" element={<Notifications/>}/>
    </Route>
}