import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import moment from 'moment';

import { Row, Typography, DatePicker} from 'antd';
import useAuth from '../../hooks/useAuth';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const Evolution = () => {

    const {auth} = useAuth()
    const [data, setData] = useState([]);


    const token = auth.token;


    useEffect(() => {
        getData(moment(Date.now()),moment(Date.now() - 50 * 24 * 3600 * 1000) );

    },[]);

    const getData = (start, end) =>  {
    

        axios({
            method:'POST',
            url: `${process.env.REACT_APP_API}/fund/btwdate`,
            data:{start:start,end:end},
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setData(response.data);
        })
        .catch(error => {

        })
    }

    const rangeOnChange = (dates, dateString) => {
      getData(moment(dateString[1]),moment(dateString[0]));
    }

    return (
        <>
        <Title level={4}>Evolución</Title>
        <RangePicker placeholder={['Desde','Hasta']} onChange={rangeOnChange} />
        <Row>
          <LineChart
            height={500}
            width={700}
            data={data}
            margin={{
              top: 40, left:0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={(tickItem) => moment(tickItem).format('DD/MM/YYYY')}/>
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="mvis" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="btc" stroke="#82ca9d" />
            <Line type="monotone" dataKey="maquina" stroke="#000" />
          </LineChart>
        </Row>
    </>
    );
}

export default Evolution;