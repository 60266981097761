import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import Moment from 'react-moment';
import {  PageHeader,  Table , Row, Col} from 'antd';
import InvertionStats from '../invertion/InvertionStats';
import { useNavigate, useParams } from 'react-router-dom';
import {
    isMobile
  } from "react-device-detect";
import useAuth from '../../hooks/useAuth';

const ListMovement = () => {

    const navigate = useNavigate(-1);

    const {auth} = useAuth()

    const {invertion:invertionId, account:accountId} = useParams();

    const columnsMaquina = [
        {
            title : 'Fecha',
            dataIndex : 'date',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Fecha</div>}
                <Moment format="DD/MM/YYYY">{text}</Moment>
                </>
            )
        },
        {
            title : 'Operación',
            dataIndex : ['operation','name'],
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Operación</div>}
                <span>{text === 'Subscription' ? 'Suscripción' : text}</span>
                </>
            )
        },
        {
            title: 'Valor Unit',
            dataIndex: 'amountConvertion',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Valor de Unit</div>}
                <span>{text.toFixed(2)}</span>
                </>
            )
        },
        {
            title : 'Cantidad',
            dataIndex : 'amount',
            render:(text,record, index)=>(
                <>
                {isMobile && <div className="label-mobile">Cantidad</div>}
                <span>{record.operation.name !== 'Subscription' ? '-'+ (record.amountConvertion*record.amount).toFixed(2) : (record.amountConvertion*record.amount).toFixed(2)  }</span>

                </>
            )
        }

    ];

    const columnsBTC = [
        {
            title : 'Fecha',
            dataIndex : 'date',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Fecha</div>}
                <Moment format="DD/MM/YYYY">{text}</Moment>
                </>
            )
        },
        {
            title : 'Operación',
            dataIndex : ['operation','name'],
            render:(text)  => {
                let textModified = text;
                if(text === 'Subscription'){
                    textModified = 'Deposito';
                }
                if(text === 'Rescate'){
                    textModified = 'Retiro';
                }
                return  <>
                {isMobile && <div className="label-mobile">Operación</div>}
                {textModified}
                </>
            
            }
        },
        {
            title: 'Cantidad de BTC',
            dataIndex: 'amount',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Cantidad de BTC</div>}
                {text.toFixed(2)}
                </>
            )
        },
    ];


    const columnsUSDT = [
        {
            title : 'Fecha',
            dataIndex : 'date',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Fecha</div>}
                <Moment format="DD/MM/YYYY">{text}</Moment>
                </>
            )
        },
        {
            title : 'Operación',
            dataIndex : ['operation','name'],
            render:(text)  => {
                let textModified = text;
                if(text === 'Subscription'){
                    textModified = 'Deposito';
                }
                if(text === 'Rescate'){
                    textModified = 'Retiro';
                }
                return  <>
                {isMobile && <div className="label-mobile">Operación</div>}
                {textModified}
                </>
            
            }
        },
        {
            title: 'Cantidad',
            dataIndex: 'amount',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Cantidad</div>}
                {text.toFixed(2)}
                </>
            )
        },
    ];


    const [data, setData] = useState([]);
    const [stats, setStats] = useState([]);
    const [account, setAccount] = useState({});

    const token = auth.token;


    const [loadingAccount, setLoadingAccount] = useState(true);

    const [columns, setColumns] = useState();

    useEffect(() => {

        getData();
        getStats();
    },[invertionId]);


    useEffect(() => {
        getAccount();
    },[accountId]);

    const getData = () =>  {
    
       

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/movement/invertion/${invertionId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setData(response.data);
        })
        .catch(error => {

      
        })
    }



    const getStats = () =>  {
    

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/invertion/statsinvertion/${invertionId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setStats(response.data.stats);
        })
        .catch(error => {
          
        })
    }

    const getAccount = () => {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/account/${accountId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoadingAccount(false);
            if(response.data.name === 'USDT(stable coin)'){
                setColumns(columnsUSDT);
            }
            if(response.data.name === 'Custodia'){
                setColumns(columnsBTC);
            }
            if(response.data.name === 'Maquina I'){
                setColumns(columnsMaquina);
            }
            setAccount(response.data);
        })
        .catch(error => {

        })       
    };

    const expandRender = record => {
        if(record.operation.name === 'Subscription'){
            return (
                <Row className="movement-detail">
                    <Col span={8}>Depositado:<br></br>{(record.amount * record.amountConvertion / 0.98).toFixed(2)}</Col>
                    <Col span={8}>Management Fee:<br></br>{(record.amount * record.amountConvertion / 0.98 * 0.02).toFixed(2)}</Col>
                    <Col span={8}>Suscripto:<br></br>{(record.amount * record.amountConvertion).toFixed(2)}</Col>
                </Row>
                )
        }
        if(record.operation.name === 'Managment Fee'){
            return (
                <Row className="movement-detail">
                    <Col span={8}>Cant. momento del cobro:<br></br> {(record.startUsd).toFixed(2)}</Col>
                    <Col span={8}>Management Fee:<br></br>  {(record.amount * record.amountConvertion).toFixed(2)}</Col>
                    <Col span={8}>Desp del cobro:<br></br> {(record.endUsd).toFixed(2)}</Col>
                </Row>
                )
        }
        if(record.operation.name === 'Performance Fee'){
            return (
                <Row className="movement-detail">
                    <Col span={6}>Cant. inicio del periodo:<br></br>{(record.startUsd).toFixed(2)}</Col>
                    <Col span={6}>Profit:<br></br>{(record.profit).toFixed(2)} </Col>
                    <Col span={6}>Performance Fee:<br></br> {(record.amount * record.amountConvertion).toFixed(2)}</Col>
                    <Col span={6}>Cant. Desp del cobro:<br></br> {(record.endUsd).toFixed(2)}</Col>
                </Row>
                )
        }
        if(record.operation.name === 'Rescate'){
            return (
                <Row className="movement-detail">
                    <Col span={6}>Profit de Unit rescatados:<br></br> {(record.profit).toFixed(2)} </Col>
                    <Col span={6}>Performance Fee:<br></br> {record.perFee || '-'}</Col>
                    <Col span={6}>Fee Rescate:<br></br> {record.rescateFee || '-'}</Col>
                    <Col span={6}>Cantida enviada:<br></br> {(record.endUsd).toFixed(2)}</Col>
                </Row>
                )
        }
        
    }

    const table = () => (
        
        <Table className="client-movement-list" style={{marginTop:'30px'}} columns={columns} dataSource={data}  rowKey="_id"
        expandable={{
            expandedRowRender: expandRender,
            rowExpandable: record => account.name === 'Maquina I',
          }}/>
        
    );

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={"Todos los movimientos"}
        />
        <div className="body-inner">
        <InvertionStats stats={stats} account={account} />
        {!loadingAccount && table()}   
        </div>
    </Body>
    );
}

export default ListMovement;