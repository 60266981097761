import React, {useState, useEffect} from 'react';
import Body from './Body';
import { Spin, List, PageHeader } from 'antd';
import Moment from 'react-moment';
import axios from 'axios';

import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const CronList = () => {

    const navigate = useNavigate();

    const [notifications, setNotifications] = useState([]);


    const {auth} = useAuth();

    const token = auth.token;

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getNotifications();
    },[]);


    const getNotifications = (page) =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/cron`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            if(response.data){
                setLoading(false);
                setNotifications(response.data.reverse());
            }

        })
        .catch(error => {
    

        })
    
    }

    const onChange = (pageNumber) => {
        getNotifications(pageNumber)
    };

    const showNotifications = () => {
        return (
        <List
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={item => (
          <List.Item extra={<Moment format="DD/MM/YYYY">{item.date}</Moment>}>
            <List.Item.Meta
              title="Cron Job"
              description={'Deshabilitamos ' + item.data.disabled.length + ' usuarios'}
            />
          </List.Item>
        )}
      />)

       
    }

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title="Cron Jobs"
        />
        <div className="body-inner">
        {isLoading && <Spin indicator={LoadingOutlined} />}
        <>{ !isLoading && showNotifications() }</>
        </div>
    </Body>
    );
}

export default CronList;