import { Route } from 'react-router-dom';
import RequireAuth from '../auth/RequireAuth';

import AdminDashboard from '../admin/dashboard/AdminDashboard';
import AddAccount from '../admin/account/AddAccount';
import ListAccount from '../admin/account/ListAccount';
import AddInvertion from '../admin/invertion/AddInvertion';
import ListInvertion from '../admin/invertion/ListInvertion';
import AddMovement from '../admin/movement/AddMovement';
import ListMovement from '../admin/movement/ListMovement';
import AddOperation from '../admin/operation/AddOperation';
import ListOperation from '../admin/operation/ListOperation';
import FundLogAdmin from '../admin/fundlog/FundLog';
import ListUser from '../admin/user/ListUser';
import AddEditUser from '../admin/user/AddEditUser';
import PageEvolutionAdmin from '../admin/dashboard/PageEvolution';
import PageShareAdmin from '../admin/dashboard/PageShare';
import PortfolioLogAdmin from '../admin/portfolio/PortfolioLog';
import ListInvertionByUser from '../admin/invertion/ListInvertionByUser';
import LTDFundLog from '../admin/fundltd/LTDFundLog';
import AddFundSerie from '../admin/fundltd/AddFundSerie';
import ListSeries from '../admin/fundltd/ListSeries';
import Estimate from '../admin/estimate/Estimate';
import DisableUsers from '../admin/user/DisableUsers';
import PagePortfolioAdmin from '../admin/dashboard/PagePortfolio';
import AddPortfolioCategory from '../admin/portfolio/AddPortfolioCategory';
import CoingeckoList from '../admin/coingecko/CoingeckoList';
import CoingeckoTable from '../admin/coingecko/CoingeckoTable';
import ListPortfolioCategory from '../admin/portfolio/ListPorfolioCategory';
import ClientInvertion from '../subscriber/invertion/ListInvertion';
import CronList from '../core/CronList';

export default function AdminRoutes() {
    return <Route path="admin" element={<RequireAuth allowedRole="admin"/>}>
        <Route path="perfomance" element={<PageEvolutionAdmin/>}/>
        <Route path="unit" element={<PageShareAdmin/>}/>
        <Route path="proyection" element={<Estimate/>}/>
        <Route path="chartportfolio" element={<PagePortfolioAdmin/>}/>
        <Route exact path="ltd" element={<LTDFundLog/>}/>
        <Route exact path="maquina" element={<FundLogAdmin/>}/>
        <Route exact path="portfolio" element={<PortfolioLogAdmin/>}/>
        <Route path="portfolio/add" element={<AddPortfolioCategory/>}/>
        <Route path="portfolio/list" element={<ListPortfolioCategory/>}/>
        <Route path="series/add" element={<AddFundSerie/>}/>
        <Route path="series/list" element={<ListSeries/>}/>
        <Route exact path="dashboard" element={<AdminDashboard/>}/>
        <Route path="account/add" element={<AddAccount/>}/>
        <Route path="account/edit/:id"  element={<AddAccount/>}/>
        <Route path="account/list" element={<ListAccount/>}/>
        <Route path="invertion/add" element={<AddInvertion/>}/>
        <Route path="invertion/edit/:invertion" element={<AddInvertion/>}/>
        <Route exact path="invertion/list" element={<ListInvertion/>}/>
        <Route path="invertion/list/:id" element={<ListInvertionByUser/>}/>
        <Route path="invertion/:id/list/:account" element={<ClientInvertion/>}/>
        <Route path="movement/edit/:movement" element={<AddMovement/>}/>
        <Route path="movement/add/:invertion" element={<AddMovement/>}/>
        <Route path="movement/invertion/:investor/:invertion/:account" element={<ListMovement/>}/>
        <Route path="operation/add" element={<AddOperation/>}/>
        <Route path="operation/edit/:id" element={<AddOperation/>}/>
        <Route path="operation/list" element={<ListOperation/>}/>
        <Route path="user/list" element={<ListUser/>}/>
        <Route exact path="user/add" element={<AddEditUser/>}/>
        <Route exact path="user/disable" element={<DisableUsers/>}/>
        <Route exact path="user/edit/:id" element={<AddEditUser/>}/>
        <Route exact path="coingecko/list" element={<CoingeckoList/>}/>
        <Route exact path="coingecko/table"  element={<CoingeckoTable/>}/>
        <Route exact path="cron" element={<CronList/>}/>

    </Route>
}