import React, {useState} from 'react';
import Body from '../core/Body';
import axios from 'axios';
import logo from '../assets/images/logoblack.png';

import { Form, Row, Col, Button, message, Input, Tooltip } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';

const Forgot = () => {
    const [values, setValues] = useState({
        email:'',
        buttonText: 'Recuperar contraseña'
    });

    const {email, buttonText} = values;

    const handleChange = (name) => (event) => {
        setValues({...values,[name]:event.target.value});
    }

    const clickSubmit = event =>  {
        
        event.preventDefault();

        setValues({...values,buttonText: 'Cargando..'});
        axios({
            method:'PUT',
            url: `${process.env.REACT_APP_API}/forgot-password`,
            data: {email}
        })
        .then(response => {

            setValues({...values, email:'', buttonText:'Recuperar contraseña'});
            
            message.success(response.data.message);

        })
        .catch(error => {
            
            setValues({...values, buttonText:'Recuperar contraseña'});

            message.error(error.response.data.message);

        })
    }

    const forgotForm = () => (
        <Row >
            <Col xl={{offset:8 , span:8}} xs={{offset:2, span:20}} >
                <Form.Item>
                <Input
                        onChange={handleChange('email')}
                        value={email}
                        placeholder="Ingresa tu email"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        suffix={
                            <Tooltip title="El usuario sera proporcionado por el administrador">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        }
                        />
                </Form.Item>

                <Button type="primary"  onClick={clickSubmit}>{buttonText}</Button>
    
            </Col>
       </Row>
    );

    return (
        <>
    <div className="login-header">
        <img src={logo} className="logo-signin" alt="logo"/>
        <h1><b>Recupera</b> tu cuenta</h1>
    </div>
    {forgotForm()}</>
    );
}

export default Forgot;