import React, { useState, useEffect } from "react";
import Body from "./Body";
import axios from "axios";
import { updateUser } from "../auth/helpers";
import { Form, message, Button, Input, Tabs } from "antd";
import useAuth from "../hooks/useAuth";
import SetupOTP from "./SetupOTP";

const { TabPane } = Tabs;

const Profile = () => {
    const { auth, setAuth } = useAuth();

    const [form] = Form.useForm();

    const [values, setValues] = useState({
        role: "",
        name: "",
        email: "",
        secret: "",
        password: "",
        buttonText: "Guardar",
    });

    const { buttonText, secret } = values;

    const token = auth.token;

    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/user/${auth.user._id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                const { role, name, email, secret } = response.data;
                setValues({ ...values, role, name, email, secret });
                form.resetFields();
            })
            .catch((response) => {
                if (response.status === 401) {
                    setAuth("");
                }
            });
    };

    const handleSubmit = (formValues) => {

        const formData = {...formValues, id: auth.user._id};

        setValues({ ...values, buttonText: "Guardando.." });

        axios({
            method: "PUT",
            url: `${process.env.REACT_APP_API}/user/profile`,
            data: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                updateUser(response, () => {
                    setValues({ ...values, buttonText: "Guardar" });
                    message.success("Perfil guardado");
                });
            })
            .catch((error) => {
                setValues({ ...values, buttonText: "Guardar" });
                message.error(error.response.data.error);
            });
    };

    const profileForm = () => (
        <Form
            form={form}
            layout="vertical"
            initialValues={values}
            onFinish={handleSubmit}
            wrapperCol={{
                span: 4,
            }}
        >
            <Form.Item name="name" label="Nombre">
                <Input readOnly autoComplete="off" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {buttonText}
                </Button>
            </Form.Item>
        </Form>
    );

    return (
        <Body>
            <div className="body-inner">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Perfil" key="1">
                        {profileForm()}
                    </TabPane>
                    <TabPane tab="Seguridad" key="2">
                        <SetupOTP hasSecret={secret} />
                    </TabPane>
                </Tabs>
            </div>
        </Body>
    );
};

export default Profile;
