import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Body from "../../core/Body";
import axios from "axios";
import Moment from "react-moment";
import { PageHeader, Typography, Statistic, Button, Table } from "antd";
import { UnorderedListOutlined, PlusOutlined } from "@ant-design/icons";
import InvertionStats from "./InvertionStats";
import { isMobile } from "react-device-detect";
import useAuth from "../../hooks/useAuth";
const { Title } = Typography;

const ListInvertion = () => {
	const navigate = useNavigate();

	const { auth } = useAuth();

	const { account: accountId, id: userId } = useParams();

	const [accountLoaded, setAccountLoaded] = useState(false);
	const [account, setAccount] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [user, setUser] = useState({
		name: "",
	});
	const [stats, setStats] = useState({
		shareValue: 0,
		sharesTotal: 0,
		subscription: 0,
		valuation: 0,
		profit: 0,
	});

	const [tableTitle, setTableTitle] = useState("Detalles de inversiones");

	const [labels, setLabels] = useState({
		fecha: "Fecha",
		cantidad: "Cantidad",
		suscripto: "Suscripto",
		title: "inversiones",
	});

	const [data, setData] = useState([]);
	const [columns, setColumns] = useState([]);

	const token = auth.token;

	useEffect(() => {
		getData(accountId);
		getAccount();
		getAccounts();
	}, [accountId, userId]);

	useEffect(() => {
		addColumns();
	}, [account]);

	const addColumns = () => {
		let columnsTemp = [];

		let dateColumn = {
			title: labels.fecha,
			dataIndex: ["date"],
			key: ["date"],
			render: (text) => (
				<>
					{isMobile && (
						<div className="label-mobile">{labels.fecha}</div>
					)}
					{text &&
						(<Moment format="DD/MM/YYYY">{text}</Moment> ||
							"No hay fecha")}
				</>
			),
		};

		columnsTemp.push(dateColumn);

		let subscriptionColumn = {
			title: labels.suscripto,
			dataIndex: ["subscription"],
			key: ["subscription"],
			render: (record) => (
				<>
					{isMobile && (
						<div className="label-mobile">{labels.suscripto}</div>
					)}
					<Statistic
						precision={2}
						prefix="$"
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		if (account.name === "Maquina I" || auth.user.role === "admin") {
			columnsTemp.push(subscriptionColumn);
		}

		let sharesLeftColumn = {
			title: labels.cantidad,
			dataIndex: ["sharesLeft"],
			key: ["sharesLeft"],
			render: (record) => (
				<>
					{isMobile && (
						<div className="label-mobile">{labels.cantidad}</div>
					)}
					<Statistic
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		if (
			(account.name !== "Maquina I LTD" &&
				account.name !== "Maquina I") ||
			auth.user.role === "admin"
		) {
			columnsTemp.push(sharesLeftColumn);
		}

		let valuationColumn = {
			title: "Valor Actual",
			dataIndex: ["valuation"],
			key: ["valuation"],
			render: (record) => (
				<>
					{isMobile && (
						<div className="label-mobile">Valor Actual</div>
					)}
					<Statistic
						precision={2}
						prefix="$"
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		if (
			account.name !== "Maquina I LTD" &&
			account.name !== "USDT(stable coin)"
		) {
			columnsTemp.push(valuationColumn);
		}

		let unitColumn = {
			title: "Units",
			dataIndex: ["amount"],
			key: ["amount"],
			render: (record) => (
				<>
					{isMobile && <div className="label-mobile">Units</div>}
					<Statistic
						precision={2}
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		let valueColumn = {
			title: "Valor Unit",
			dataIndex: ["value"],
			key: ["value"],
			render: (record) => (
				<>
					{isMobile && <div className="label-mobile">Valor Unit</div>}
					<Statistic
						precision={2}
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		let totalColumn = {
			title: "Valor",
			key: ["valor"],
			render: (record) => (
				<>
					{isMobile && <div className="label-mobile">Valor</div>}
					<Statistic
						precision={2}
						value={record.amount * record.value}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		let monedaColumn = {
			title: "Moneda",
			key: ["moneda"],
			render: (record) => (
				<>
					{isMobile && <div className="label-mobile">Moneda</div>}
					<Statistic
						value="USD"
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		let serieColumn = {
			title: "Serie",
			dataIndex: ["serieType"],
			key: ["serieType"],
			render: (record) => (
				<>
					{isMobile && <div className="label-mobile">Serie</div>}
					<Statistic
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		if (account.name === "Maquina I LTD") {
			columnsTemp.push(serieColumn);
			columnsTemp.push(valueColumn);
			columnsTemp.push(unitColumn);
			columnsTemp.push(monedaColumn);
			columnsTemp.push(totalColumn);
		}

		let precioColumn = {
			title: "Precio de Compra",
			dataIndex: ["precio"],
			key: ["precio"],
			render: (record) => (
				<>
					{isMobile && (
						<div className="label-mobile">Precio de Compra</div>
					)}
					<Statistic
						precision={2}
						prefix="$"
						value={record}
						valueStyle={{ fontSize: "1.3rem" }}
					/>
				</>
			),
		};

		if (account.name === "Custodia") {
			columnsTemp.push(precioColumn);
		}

		let profitColumn = {
			title: "Profit",
			key: ["profit"],
			render: (record) => (
				<>
					{isMobile && <div className="label-mobile">Profit</div>}
					<div className="profit-td">
						<Statistic
							precision={2}
							prefix="$"
							key={record}
							value={record.profit}
							valueStyle={{ fontSize: "1.3rem" }}
						/>
						<span>
							(
							{Math.sign(record.percent) === 1
								? "+" + record.percent
								: record.percent}
							%)
						</span>
					</div>
				</>
			),
		};

		if (account.name === "Maquina I") {
			columnsTemp.push(profitColumn);
		}

		let actionColumn = {
			title: "Acciones",
			dataIndex: "",
			key: "x",
			width: "10%",
			render: (record) => {
				return (
					<>
						{auth.user.role === "admin" && (
							<Link to={`/admin/movement/add/${record._id}`}>
								<Button type="primary">
									<PlusOutlined />
								</Button>
							</Link>
						)}
						{auth.user.role === "admin" && (
							<Link
								to={`/admin/movement/invertion/${userId}/${record._id}/${record.account._id}`}
							>
								<Button>
									<UnorderedListOutlined />
								</Button>
							</Link>
						)}
						{auth.user.role !== "admin" && (
							<Link
								to={`/private/movement/invertion/${auth.user._id}/${record._id}/${record.account._id}`}
							>
								<Button>Movimientos</Button>
							</Link>
						)}
					</>
				);
			},
		};

		if (account.name !== "Maquina I LTD") columnsTemp.push(actionColumn);

		setColumns(columnsTemp);
	};

	const getData = (account) => {
		axios({
			method: "GET",
			url: `${process.env.REACT_APP_API}/user/invertion/${userId}/${account}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setData(response.data.invertions);
				setUser(response.data.user);
				setStats(response.data.stats);
			})
			.catch((error) => {
				setData([]);
				setStats({
					shareValue: 0,
					sharesTotal: 0,
					subscription: 0,
					valuation: 0,
					profit: 0,
				});
			});
	};

	const getAccount = () => {
		axios({
			method: "GET",
			url: `${process.env.REACT_APP_API}/account/${accountId}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				const name = response.data.name;
				if (name === "Custodia") {
					setTableTitle("Detalle de depósitos");
					setLabels({
						...labels,
						cantidad: "Cantidad Bitcoin",
						fecha: "Fecha de depósito",
						title: "depósitos",
					});
				} else if (name === "USDT(stable coin)") {
					setTableTitle("Movimientos");
					setLabels({
						...labels,
						cantidad: "Cantidad",
						title: "depósitos",
					});
				} else if (name === "USDT(stable coin)") {
					setTableTitle("Movimientos");
					setLabels({
						...labels,
						cantidad: "Cantidad",
						title: "depósitos",
					});
				} else {
					setTableTitle("Detalle de inversiones");
					setLabels({
						...labels,
						fecha: "Fecha de Suscripción",
						title: "inversiones",
					});
				}

				setAccount(response.data);
				setAccountLoaded(true);
			})
			.catch((error) => {});
	};

	const getAccounts = () => {
		axios({
			method: "GET",
			url: `${process.env.REACT_APP_API}/account`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				setAccounts(response.data);
			})
			.catch((error) => {});
	};

	const table = () => (
		<div className="table-invertion">
			<Table columns={columns} dataSource={data} rowKey="_id" />
		</div>
	);

	return (
		<Body>
			<PageHeader
				className="site-page-header"
				onBack={() => navigate(-1)}
				title={
					"Consolidado de " +
					labels.title +
					" en " +
					account.name +
					" de " +
					user.name
				}
			/>
			<div className="body-inner">
				<InvertionStats stats={stats} account={account} />
				{auth.user.role === "admin" &&
					accounts.map((el, index) => {
						return (
							<Link
								key={"btn_" + index}
								to={`/admin/invertion/${userId}/list/${el._id}`}
							>
								<Button
									className={
										el._id === accountId
											? "active-account"
											: ""
									}
									style={{ marginRight: "1rem" }}
								>
									{el.name}
								</Button>
							</Link>
						);
					})}
				<Title level={4} style={{ marginTop: "3rem" }}>
					{tableTitle}
				</Title>
				{accountLoaded && table()}
			</div>
		</Body>
	);
};

export default ListInvertion;
