import React, {useState} from 'react';
import Body from '../../core/Body';
import {PageHeader} from 'antd';
import PerfomancePie from '../../common/perfomance/PerfomancePie';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
const PagePortfolio = () => {

    const navigate = useNavigate();

    const [currentDate, setCurrentDate] = useState(moment().format('DD/MM/YYYY'));

    const lastDate = (date) => {
        setCurrentDate(moment(date).format('DD/MM/YYYY'));
    }

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={'La composición de la cartera de Maquina Capital I al '+ currentDate }
        />
        <div className="body-inner">
            <PerfomancePie percentage="true" parentCallback={lastDate}/>
        </div>
    </Body>
    );
}

export default PagePortfolio;