import React from 'react';
import {  Table } from 'antd';
import { Button} from 'antd';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
const LastMovements = ({lastMovements}) => {


    const columns = [
        {
            title : 'Fecha',
            dataIndex : 'date',
            render:(text)=><Moment format="DD/MM/YYYY">{text}</Moment>
        },
        {
            title: 'Inversor',
            render:(record) => record.invertion.investor.name
        },
        {
            title : 'Operación',
            dataIndex : ['operation','name']
        },
        {
            title: 'Valor de Unit',
            dataIndex: 'amountConvertion'
        },
        {
            title : 'Cantidad Unit',
            dataIndex : 'amount'
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>       
                <Link style={{marginLeft:'15px'}} to={`/admin/movement/edit/${record._id}`}><Button>Editar</Button></Link>
            </>
            )
            },
        }
    ];



    return (
        <Table columns={columns} dataSource={lastMovements}  rowKey="_id"/> 
    );
}

export default LastMovements;