import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Body from '../../core/Body';
import axios from 'axios';
import Moment from 'react-moment';
import { PageHeader, Modal, Input, Button, Table, message } from 'antd';
import {DeleteOutlined,EditOutlined} from '@ant-design/icons';
import {
    isMobile
  } from "react-device-detect";
import InvertionStats from '../../subscriber/invertion/InvertionStats';
import useAuth from '../../hooks/useAuth';
const {Search} = Input;

const ListMovement = () => {
    const navigate = useNavigate();
    const {auth} = useAuth()
    const {invertion: invertionId, account:accountId} = useParams();

    const columns = [
        {
            title : 'Fecha',
            dataIndex : 'date',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Fecha</div>}
                <Moment format="DD/MM/YYYY">{text}</Moment>
                </>
            )
        },
        {
            title : 'Operación',
            dataIndex : ['operation','name'],
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Operación</div>}
                {text === 'Subscription' ? 'Suscripción' : text}
                </>
            )
        },
        {
            title : 'Unit restados',
            render:(record)=>(
                <>
                {isMobile && <div className="label-mobile">Unit restados</div>}
                {record.operation.name === 'Subscription' ? 0 : record.amount}
                </>
            )
        },
        {
            title : 'Unit restantes',
            dataIndex : ['sharesLeft'],
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Unit restantes</div>}
                {text.toFixed(2)}
                </>
            )
        },
        {
            title: 'Valor Unit',
            dataIndex: 'amountConvertion',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Valor de Unit</div>}
                {text.toFixed(2)}
                </>
            )
        },
        {
            title : 'Inicio Periodo',
            dataIndex : 'startUsd',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Inicio Periodo</div>}
                {text ? text.toFixed(2) : ''}
                </>
            )
        },
        {
            title : 'Profit',
            dataIndex : 'profit',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Profit</div>}
                {text ? text.toFixed(2) : ''}
                </>
            )
        },
        {
            title : 'Fees',
            dataIndex:'fees',
            render:(text)=>{
                let fees = '';
                let feeOne = '';
                let feeTwo = '';
                if(Array.isArray(text)){
                    if(text[0]){
                        feeOne = text[0];
                    }
                    if(text[1]){
                        feeTwo = text[1];
                    }
                    return (<>
                        {isMobile && <div className="label-mobile">Fees</div>}
                        {feeOne ? feeOne : ''}<br></br>{feeTwo ? feeTwo : ''}
                        </>)
                }else{
                    fees = text;
                    return (<>
                    {isMobile && <div className="label-mobile">Fees</div>}
                        {fees ? fees : ''}
                    </>)
                }


            }
        },
        {
            title : 'Final Periodo',
            dataIndex : 'endUsd',
            render:(text)=>(
                <>
                {isMobile && <div className="label-mobile">Final Periodo</div>}
                {text ? text.toFixed(2) : ''}
                </>
            )
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>       
                <Link style={{marginLeft:'15px'}} to={`/admin/movement/edit/${record._id}`}><Button><EditOutlined /></Button></Link>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id)}><DeleteOutlined /></Button>
            </>
            )
            },
        }
    ];

    const [showModal, setShowModal] = useState(false);

    const [deleteId, setDeleteId] = useState('');


    const [data, setData] = useState([]);


    const token = auth.token;



    useEffect(() => {
        getData();
        getStats();

    },[invertionId]);

    useEffect(() => {
        getAccount();
    },[accountId]);

    const getAccount = () => {
        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/account/${accountId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setAccount(response.data);
        })
        .catch(error => {

        })       
    };

    const getData = () =>  {
    


        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/movement/invertion/${invertionId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setData(response.data);
        })
        .catch(error => {
       
        })
    }

    const [stats, setStats] = useState([]);
    const [account, setAccount] = useState({});


    const handleOk = e => {

        axios({
            method:'DELETE',
            url: `${process.env.REACT_APP_API}/movement/${deleteId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setShowModal(false);
            setData(data.filter(el => el._id !== deleteId));
            setDeleteId('');
        })
        .catch(error => {
            setShowModal(false);
            setDeleteId('');
            message.error('No se pudo eliminar el movimiento, intente otra vez.');
        })
        
    };

    const getStats = () =>  {
    
        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/invertion/statsinvertion/${invertionId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setStats(response.data.stats);
        })
        .catch(error => {

        })
    }

    const handleCancel = e => {
        setShowModal(false);
    };

    const deleteRow = (id) => {
        setDeleteId(id);
        setShowModal(true);
    }


    const table = () => (
        
        <Table scroll={{ x: true }} columns={columns} dataSource={data}  rowKey="_id"/>
        
    );

    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title={"Todos los movimientos"}
            subTitle="Movimientos"
        />
        <Modal
          title="Estas seguro que deseas eliminar este movimiento?"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Eliminar movimiento"
          okButtonProps={{ danger:true}}
        >
        </Modal>

        <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>
        <Search style={{ width: 400 }} placeholder="Buscar movimientos" enterButton />
        <Link to={`/admin/movement/add/${invertionId}`}><Button type="primary">Nuevo Movimiento</Button></Link>
        </div>
        <InvertionStats stats={stats} account={account} />
        {table()}   
    </Body>
    );
}

export default ListMovement;