import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import { Form, PageHeader, Button, message, Input } from 'antd';
import useAuth from '../../hooks/useAuth';

const Operation = () => {

    const {auth} = useAuth();

    const {id} = useParams();

    const [values, setValues] = useState({
        name: '',
        slug: '',
        buttonText: 'Submit'
    });

    const [form] = Form.useForm();
    
    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const {buttonText} = values;


    useEffect(() => {
        if(id){
            getOperation();
            setValues({buttonText:'Editar operacion'});
        }
    },[id]);

    const getOperation = () => {

        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/operation/single/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            const {_id, name, slug} = response.data;
            setValues({...values, _id, name, slug});
            form.resetFields();
        })
        .catch(error => {
            
        });        
    }

    const handleSubmit = values =>  {

        setValues({...values,buttonText: 'Submitting..'});
        
        setLoading(true);

        let method = 'POST';
        let endpoint = `${process.env.REACT_APP_API}/operation`;

        if(id){
            method = 'PUT';
            endpoint += '/' + id;
        }

        axios({
            method:method,
            url: endpoint,
            data: values,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setLoading(false);
            setValues({...values, buttonText: 'Submit'});
            message.success('Operation Type created succesfully');
        })
        .catch(error => {

            if(error.response.data){
                message.error(error.response.data.error);
            }
            
            setValues({...values, buttonText:'Submit'});
            setLoading(false);
        })
    }

    const formOutput = () => (
        <Form form={form} initialValues={values} layout="vertical" onFinish={handleSubmit}>

            <Form.Item label="Name" name="name">
                <Input placeholder="Name" />
            </Form.Item>

            <Form.Item label="Slug" name="slug">
                <Input placeholder="Slug" />
            </Form.Item>

            <Button loading={loading} type="primary" htmlType="submit">{buttonText}</Button>
        </Form>
    );

    return (
    <Body>
    <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Nuevo Tipo de Operación"
        subTitle="Operations"
    />
    {formOutput()}
        
    </Body>
    );
}

export default Operation;