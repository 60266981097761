import React, { useState, useEffect } from "react";
import Body from "./Body";
import { Spin, List, PageHeader, Pagination } from "antd";
import Moment from "react-moment";
import axios from "axios";

import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Notifications = () => {
    const navigate = useNavigate();

    const [notifications, setNotifications] = useState({
        list: [],
        total: 0,
        current: 1,
    });

    const { id } = useParams();

    const { auth } = useAuth();

    const token = auth.token;

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = (page) => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/notifications`,
            params: {
                id,
                page: page,
                limit: 10,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.data) {
                    setLoading(false);
                    setNotifications(response.data);
                }
            })
            .catch((error) => {});
    };

    const onChange = (pageNumber) => {
        getNotifications(pageNumber);
    };

    const showNotifications = () => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={notifications.list}
                renderItem={(item) => (
                    <List.Item
                        extra={<Moment format="DD/MM/YYYY">{item.date}</Moment>}
                    >
                        <List.Item.Meta
                            title={item.title}
                            description={item.text}
                        />
                    </List.Item>
                )}
            />
        );
    };

    return (
        <Body>
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(-1)}
                title="Notificaciones"
            />
            <div className="body-inner">
                {isLoading && <Spin indicator={LoadingOutlined} />}
                <>{!isLoading && showNotifications()}</>
                {notifications.total !== 0 && (
                    <Pagination
                        style={{ margin: "30px auto" }}
                        current={notifications.current}
                        pageSize={10}
                        total={notifications.total}
                        onChange={onChange}
                    />
                )}
            </div>
        </Body>
    );
};

export default Notifications;
