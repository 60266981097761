import React, {useState} from 'react';
import Body from '../../core/Body';
import axios from 'axios';

import { Form, PageHeader, Button, message, Input } from 'antd';
import useAuth from '../../hooks/useAuth';


const AddPortfolioCategory = () => {
    
    const {auth} = useAuth();
    const [values, setValues] = useState({
        amount: '',
        date: '',
        amountUsd : '',
        amountConvertion : '',
        user : auth.user._id,
        account: '',
        buttonText: 'Crear'
    });

    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const {name, buttonText} = values;

    const handleChange = (name) => (event) => {
        setValues({...values,[name]:event.target.value});
    }

    const clickSubmit = event =>  {
        
        event.preventDefault();

        setValues({...values,buttonText: 'Creando..'});
        setLoading(true);

        axios({
            method:'POST',
            url: `${process.env.REACT_APP_API}/portfoliocategory`,
            data: {name},
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setLoading(false);
            message.success('Categoria creada!');
        })
        .catch(error => {


            
            if(error.response.data){
                message.error(error.response.data.error);
            }
            
            setValues({...values, buttonText:'Crear'});
            setLoading(false);
        })
    }

    const form = () => (
        <Form>

            <Form.Item>
                <label className="text-muted">Nombre</label>
                <Input onChange={handleChange('name')} placeholder="Nombre" />
            </Form.Item>

            <Button loading={loading} type="primary" onClick={clickSubmit}>{buttonText}</Button>
        </Form>
    );

    return (
    <Body>
    <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Nueva Categoria de Portfolio"
        subTitle="Portfolio"
    />
    {form()}
        
    </Body>
    );
}

export default AddPortfolioCategory;