import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, message, Button, Input, Card } from "antd";
import { SafetyOutlined, DeleteOutlined} from "@ant-design/icons";
import useAuth from "../hooks/useAuth";

const { Meta } = Card;

const SetupOTP = ({ hasSecret }) => {
    const { auth } = useAuth();

    const [secret, setSecret] = useState(hasSecret);

    const [form] = Form.useForm();

    const [values, setValues] = useState({
        qr: "test.png",
        buttonText: "Verificar",
    });

    const { buttonText, qr } = values;

    const token = auth.token;

    useEffect(() => {
        getSecret();
    }, [auth.user._id]);

    const getSecret = () => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/otp/create-secret`,
            data: {
                id: auth.user._id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.data) {
                    const { qr: qrCode } = response.data;

                    setValues({
                        ...values,
                        qr: qrCode,
                        buttonText: "Verificar",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const deleteOtp = () => {
        axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_API}/otp/delete`,
            data: {
                id: auth.user._id
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {

            })
            .catch((error) => {

            });
    }

    const handleSubmit = (formValues) => {
        setValues({ ...values, buttonText: "Verificando.." });

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/otp/setup`,
            data: {
                id: auth.user._id,
                code: formValues.code,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                message.success("Verificado");
                setValues({ ...values, buttonText: "Verificar" });
                setSecret(true);
            })
            .catch((error) => {
                setValues({ ...values, buttonText: "Verificar" });
                console.log(error);
                message.error(error.response.data.error);
            });
    };

    const setupForm = () => (
        <div>
            <h3>Escanea el QR en tu aplicacion 2F favorita</h3>

            {values.qr && <img src={qr} alt="QR" />}

            <h3>Ingresa el código del autenticador para verificar.</h3>

            <Form
                form={form}
                layout="vertical"
                initialValues={values}
                onFinish={handleSubmit}
            >
                <Form.Item name="code" label="Código">
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {buttonText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    return (
        <>
            <div>{!secret && setupForm()}</div>
            <div>
                {secret && (
                    <Card
                        style={{ width: 300 }}
                        actions={[
                            <DeleteOutlined key="delete" onClick={deleteOtp} />,
                        ]}
                    >
                        <SafetyOutlined style={{fontSize: '36px', color: 'green', marginBottom: '20px'}} />
                        <Meta
                            title="Autenticación 2F"
                            description="La autenticación de dos factores esta habilitada" />
                    </Card>
                )}
            </div>
        </>
    );
};

export default SetupOTP;
