import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import moment from 'moment';
import { Form, Skeleton, Select, DatePicker, PageHeader, Button, message, Input } from 'antd';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const AddMovement = () => {
    const {auth} = useAuth()
    const {movement: movementId, invertion:invertionId} = useParams();


    const [values, setValues] = useState({
        buttonText: 'Crear movimiento'
    });

    const [initialValues,setInitialValues] = useState({
        date: '',
        operation: 'operation',
        amount: 0,
        amountConvertion: 0,
        invertion: invertionId
    });

    const [form] = Form.useForm();

    const token = auth.token;

    const [loadingMovement,setLoadingMovement] = useState(true);

    const [loading, setLoading] = useState(false);

    const [operationOptions, setOperationOptions] = useState([]);

    const [shareValue , setShareValue] = useState({});
    const [loadedShare, setLoadedShare] = useState(false);

    const [hiddenField, setHiddenField] = useState(true);

    const {buttonText} = values;

    useEffect(() => {
        getOperation();
    },[]);
    


    useEffect(() => {
        if(movementId){
            getMovement();
            setValues({buttonText:'Editar movimiento'});
        }else{
            setLoadingMovement(false);
            
        }
        
    },[movementId]);

    const getMovement = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/movement/${movementId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoadingMovement(false);
            const {date, invertion, operation,  amountConvertion, amount, rescateFee, perFee} = response.data;
            setInitialValues({
                date: date ? moment(date) : '',
                operation: operation._id,
                amount: amount,
                amountConvertion: amountConvertion,
                invertion: invertion,
                rescateFee : rescateFee,
                perFee : perFee
            });
            if(operation.name === 'Rescate'){
                setHiddenField(false);
            }
            form.resetFields();
        })
        .catch(error => {
            
        });        
    }

    const listOperationOptions = () => {
        return operationOptions.map(d => {return (d.name !== 'Subscription') ? <Option key={d._id}>{d.name}</Option> : null});
    }

    const getShareValue = (date) => {
        const start = date.subtract(3,'hours').startOf('day').toString();
        const end = date.endOf('day').toString();
        axios({
            method: 'POST',
            data:{start: start, end: end},
            url: `${process.env.REACT_APP_API}/fund/bydate`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setShareValue(response.data);
            setLoadedShare(true);

        })
        .catch(error => {
           
        });  
    };

    const getOperation = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/operation`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setOperationOptions(response.data);  
        })
        .catch(error => {
            
        });
    }

    const handleSubmit = values =>  {

        setValues({...values,buttonText: 'Guardando..'});
        setLoading(true);
        let btnTxt = (invertionId) ? 'Editar movimiento' : 'Crear movimiento';


        let method = 'POST';
        let endpoint = `${process.env.REACT_APP_API}/movement`;
        if(movementId){
            method = 'PUT';
            endpoint += '/' + movementId;
        }

        axios({
            method:method,
            url: endpoint,
            data:  values,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setValues({...values, buttonText:btnTxt});
            message.success('Movimiento guardado exitosamente');
            console.log('go-back');
        })
        .catch(error => {
            message.error(error.response.data.error);
            setValues({...values, buttonText:btnTxt});
            setLoading(false);
        })
    }


    
    const dateChange = (value) => {
        getShareValue(value);
    }


    const setAmountConvertion = (val) => {
        form.setFieldsValue({amountConvertion: val});
    }

    const operationChange = (value, opt) => {

        if(opt.children === 'Rescate'){
            setHiddenField(false);
        }else{
            setHiddenField(true);
        }
    }



    const getForm = () => (
        <Form form={form} {...layout} initialValues={initialValues} layout="vertical" onFinish={handleSubmit}>

            <Form.Item  label="Inversión"  name="invertion" hidden>
                <Input />
            </Form.Item>  

            <Form.Item label="Fecha" name="date">
                <DatePicker onChange={dateChange} />
            </Form.Item>

            <Form.Item label="Tipo de Operación" name="operation">
                <Select onChange={operationChange}>
                    {listOperationOptions()}
                </Select>
            </Form.Item>

            {loadedShare && (
                <>
                    <Button onClick={() => setAmountConvertion(shareValue.btcVal)}>Bitcoin: {shareValue.btcVal}</Button>
                    <Button onClick={() => setAmountConvertion(shareValue.maquina)}>Maquina: {shareValue.maquina}</Button>
                </>
            )}
            
            <Form.Item label="Valor de Unit" name="amountConvertion">
                <Input type="number"  placeholder="Valor de Unit" />
            </Form.Item>

            <Form.Item label="Cantidad Unit" name="amount">
                <Input type="number" placeholder="Cantidad Unit" />
            </Form.Item>


            <Form.Item label="Perfomance Fee (En USDT)" name="perFee" hidden={hiddenField}>
                <Input type="number" placeholder="Perfomance Fee" />
            </Form.Item>

            <Form.Item label="Fee Rescate (En USDT)" name="rescateFee" hidden={hiddenField}>
                <Input type="number" placeholder="Fee Rescate" />
            </Form.Item>

            <Button loading={loading} htmlType="submit">{buttonText}</Button>
        </Form>
    );

    return (
        
    <Body>
    <PageHeader
        className="site-page-header"
        onBack={() => console.log('go-back')}
        title="Nuevo movimiento"
        subTitle="Movimientos"
    />

    <Skeleton loading={loadingMovement} active/>
    {!loadingMovement && getForm()}
        
    </Body>
    );
}

export default AddMovement;