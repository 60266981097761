import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';

import { PageHeader,DatePicker, message,  Button, Table } from 'antd';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';


const CoingeckoTable = () => {
    const {auth} = useAuth()
    const columns = [
        {
          title: 'ID',
          dataIndex : 'id',
          key : 'id',
        },
        {
            title: 'Nombre',
            dataIndex : 'name',
            key : 'name',
        },
        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                <Button onClick={() => deleteCoin(record._id)}>Eliminar</Button>
            </>
            )
            },
        }
    ];


    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const [coins, setCoins] = useState([]);

    const [dateFilter, setDateFilter] = useState({pickedDate: moment().format("DD-MM-yyyy"), apiDate: moment().format("DD-MM-yyyy")});

    const [datanavigate, setDatanavigate] = useState([]);

    const [columnsnavigate, setColumnsnavigate] = useState([]);

    useEffect(() => {
        getData();
    },[]);
    
    useEffect(() => {

        let requests = [];

        coins.forEach(coin => {
            let endpoint = `https://api.coingecko.com/api/v3/coins/${coin.id}/navigate`;
            const request = axios.get(endpoint,{params: {date: dateFilter.apiDate}});
            requests.push(request);
        });

        setLoading(true);

        axios
        .all(requests)
        .then(
            axios.spread((...responses) => {
                // use/access the results
                let tempColumnsnavigate = [
                    {
                        title: 'Fecha',
                        key : 'fecha',
                        dataIndex: 'fecha'
                    }
                ];
                let tempDatanavigate = [];
                let prices = {};
                responses.forEach(response => {
                    tempColumnsnavigate.push(
                        {
                            title: response.data.name,
                            key : response.data.id,
                            dataIndex : response.data.id
                        }
                    );

                    prices['fecha'] = dateFilter.pickedDate;
                    prices[response.data.id] = response.data.market_data ? response.data.market_data.current_price.usd : 'N/V';
                })
                tempDatanavigate(prices);
                setColumnsnavigate(tempColumnsnavigate);
                setDatanavigate(tempDatanavigate);
                setLoading(false);
            })
        )
        .catch(errors => {
            // react on errors.
            console.error(errors);
        });
    },[coins, dateFilter]);

    const getData = () =>  {
    
    
        let endpoint = `${process.env.REACT_APP_API}/coingecko`;

        axios({
            method:'GET',
            url: endpoint,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setCoins(response.data);
        })
        .catch(error => {
           
        })
    }

    const deleteCoin = (id) => {
        let endpoint = `${process.env.REACT_APP_API}/coingecko/${id}`;

        axios({
            method:'DELETE',
            url: endpoint,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            message.success('Coin eliminada');
            coins.filter(coin => coin._id !== id);
        })
        .catch(error => {
            message.error('Error al eliminar coin');
        })
    }

    const onChangeDate = (date, dateString) => {
        const apiDate = moment(dateString).add(1, "days").format("DD-MM-YYYY");
        const pickedDate = moment(dateString).format("DD-MM-YYYY");
        setDateFilter({pickedDate: pickedDate, apiDate: apiDate});
    };

    const tablenavigate = () => (
        <Table columns={columnsnavigate} dataSource={datanavigate}  scroll={{ x: 400 }} rowKey="fecha"/>
    );

    const table = () => (
        <Table columns={columns} dataSource={coins}  rowKey="id"/>
    );
    
    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => console.log('go-back')}
            title="Coingecko"
        />
        <DatePicker onChange={onChangeDate} />
        {!loading && tablenavigate()}
        {table()}   
    </Body>
    );
}

export default CoingeckoTable;