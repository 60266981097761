import React from 'react';
import {Link} from 'react-router-dom';
import Body from '../core/Body';
import { Result, Button } from 'antd';
const GenericNotFound = () => {

    return (
    <Body>
        <Result
            status="404"
            title="404"
            subTitle="Disculpa, no encontramos lo que buscas."
            extra={<Link to="/"><Button type="primary">Inicio</Button></Link>}
        />
    </Body>
    );
}

export default GenericNotFound;