import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import useAuth from '../../hooks/useAuth';
import axios from 'axios';
import Moment from 'react-moment';
import { Row, Col, Divider,Card, Typography,Statistic, PageHeader} from 'antd';
import LastMovements from './LastMovements';
const { Title } = Typography;

const AdminDashboard = () => {

  const {auth} = useAuth();

  const token = auth.token;

  const [stats, setStats] = useState({
    lastFund:{
      date:'',
      mvis:0,
      btc:0,
      btcVal:0,
      maquina:0
    }
  });

  const [movs, setMovs] = useState([]);

  useEffect(() => {
      getStats();
      getMovs();  
  },[token]);

  const getStats = () =>  {

    axios({
        method:'GET',
        url: `${process.env.REACT_APP_API}/invertion/allstats/admin`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        setStats(response.data);
    })
    .catch(error => {
    });
  }

  const getMovs = () =>  {

    axios({
        method:'GET',
        url: `${process.env.REACT_APP_API}/movement`,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        setMovs(response.data);
    })
    .catch(error => {
    });

  }

  const table = () => (
      <>
      <Row>
        <Col span={21}>
          <Row>
            <Title level={4}>Estadisticas</Title>
          </Row>
          <Row>
            <Col span={10}>
              <Card>
                <Statistic  title="Total Unit" value={stats.totalShares} precision={2} valueStyle={{ color: '#3f8600' }}/>
              </Card>
            </Col>
            <Col span={10}>
              <Card>
                <Statistic title="Total Inversiones" value={stats.totalInvertions} precision={0} valueStyle={{ color: '#3f8600' }}/>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={21}>
          <Row>
         
            <Title level={4}>Ultimo historico al <Moment format="DD/MM/YYYY">{stats.lastFund.date}</Moment> </Title>
          </Row>
          <Row>
            <Col span={5}>
              <Card>
                <Statistic  title="MVIS" value={stats.lastFund.mvis} precision={2} valueStyle={{ color: '#3f8600' }}/>
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic  title="Bitcoin" value={stats.lastFund.btc} precision={2} valueStyle={{ color: '#3f8600' }}/>
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic title="Maquina I" value={stats.lastFund.maquina} precision={2} valueStyle={{ color: '#3f8600' }}/>
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic title="Valor Bitcoin" value={stats.lastFund.btcVal} precision={2} valueStyle={{ color: '#3f8600' }}/>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Title level={4}>Ultimos movimientos</Title>
      <LastMovements lastMovements={movs} />
  </>
  );

  return (
  <Body>
      <PageHeader
          className="site-page-header"
          onBack={() => null}
          title="Dashboard"
          subTitle="Información General"
      />
      {table()}   
  </Body>
  );
}

export default AdminDashboard;