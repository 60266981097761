import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis,ResponsiveContainer, CartesianGrid } from 'recharts';
import moment from 'moment';
import { isMobile } from "react-device-detect";
import { Row, Col, Statistic, Typography, DatePicker, PageHeader} from 'antd';
import Body from '../../core/Body';
import Perfomance from './Perfomance';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const { RangePicker } = DatePicker;
const { Title } = Typography;

const PageRendimiento = () => {
    const navigate = useNavigate();
    const {auth} = useAuth();

    const [data, setData] = useState([]);

    //const [fundGraph, setFundGraph] = useState([]);

    const token = auth.token;

    const [loading, setLoading] = useState(false);
    const [variation, setVariation] = useState({
        mvis:0,
        btc:0,
        maquina:0
    });

    useEffect(() => {
        getData('', '');
    },[]);

    const getData = (start, end) =>  {
    
        setLoading(true);

        axios({
            method:'POST',
            url: `${process.env.REACT_APP_API}/fund/btwdate`,
            data:{start:start,end:end},
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            const funds = response.data.basecien.reverse();
            setData(funds);

            setVariation(response.data.variation);
        })
        .catch(error => {
            setLoading(false);
        })
    }

    const rangeOnChange = (dates, dateString) => {
      let start = moment(dateString[0]).utcOffset(0);
      start.set({hour:0,minute:0,second:0,millisecond:0})
      start.toISOString();
      start.format();
      let end = moment(dateString[1]).utcOffset(0);
      end.set({hour:23,minute:0,second:0,millisecond:0})
      end.toISOString();
      end.format();

      getData(start,end);
    }

    return (
        <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => navigate(-1)}
            title="Rendimientos"
        />
        <div className="body-inner">
        <Row>
          <Col xl={7}  xs={24}>
            <div className="centered-mobile">
              <Title level={4} className="title-color">Elige Fechas para comparar</Title>
              <RangePicker placeholder={['Desde','Hasta']} onChange={rangeOnChange} />
            </div>
            <Title style={{marginTop:'40px'}} level={3}>Variación</Title>
            <div>
              <div className="rend-row">
                <h6 className="block-title">Maquina Capital I</h6>
                <Statistic prefix={variation.maquina > 0 ? '+' : ''} suffix="%" value={variation.maquina}  precision={2} className="rend-stat" valueStyle={{ color: '#00aed8'}}/>
              </div>
            </div>
            <div>
              <div className="rend-row">
                <h6 className="block-title">BTC</h6>
                <Statistic prefix={variation.btc > 0 ? '+' : ''} suffix="%" value={variation.btc}  precision={2} className="rend-stat" valueStyle={{ color: '#2f575f'}}/>
              </div>
            </div>
            <div>
              <div className="rend-row" >
                <h6 className="block-title">Benchmark</h6>
                <Statistic prefix={variation.mvis > 0 ? '+' : ''} suffix="%" value={variation.mvis}  precision={2} className="rend-stat" valueStyle={{ color: '#136a90'}}/>
              </div>
            </div>
            <span className="default-value-label">Valor por defecto: Últimos 365 días</span>
            
          </Col>
            <Col xl={{span:12, offset:3}} xs={24} className="vertical-padding-mobile">
              <ResponsiveContainer width='100%' height={isMobile ? 250  : 400}>
                <LineChart
                  data={data}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis axisLine={false} padding={{ left: 0, right: 40 }}  style={{ fontSize:'12px'}}  minTickGap={20}  dataKey="date"  interval="preserveEnd" tickFormatter={(tickItem) => moment(tickItem).format('DD/MM/YYYY')}/>
                  <YAxis axisLine={false} width={40} />
                  <Line type="monotone" dataKey="maquina" name="Maquina Capital I" stroke="#00aed8"  strokeWidth={2} dot={false} />
                  <Line type="monotone" dataKey="mvis" name="MVIS" stroke="#2f575f" strokeWidth={2}  dot={false} />
                  <Line type="monotone" dataKey="btc" name="BTC" stroke="#136a90" strokeWidth={2}  dot={false} />
                </LineChart>
              </ResponsiveContainer>
          </Col>
        </Row>
        <Row style={{margin:'2rem 0'}}>
          <Col span={24}>
            <Title level={3}>Cuadro de Rendimientos</Title>
            <Perfomance />
          </Col>
        </Row>
        </div>
    </Body>
    );
}



export default PageRendimiento;