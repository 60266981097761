import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import AddFundLog  from './AddLTDFundLog';
import { PageHeader, Divider, Table, Button } from 'antd';
import useAuth from '../../hooks/useAuth';

const LTDFundLog = () => {

    const {auth} = useAuth()

    const [columns, setColumns] = useState([]);

    const columnsBase = [
        {
          title: 'Date',
          dataIndex : ['date'],
          key : ['date']
        },

        {
            title: 'Acciones',
            dataIndex: '',
            key: 'x',
            render: (record) => {
            return (
            <>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => editRow(record)}>Editar</Button>
                <Button type="link" style={{marginLeft:'15px'}} onClick={() => deleteRow(record._id)}>Eliminar</Button>
            </>
            )
            },
        }
    ];



    const [data, setData] = useState([]);

    const token = auth.token;

    const [edit,setEdit] = useState({});

    useEffect(() => {
        getSeries();
        getData();
    },[]);

    
    const getSeries = () =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/fundseries`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            let catColumnstemp = [];
            response.data.forEach(el => {
                catColumnstemp.push(
                    {
                        title : el.name,
                        dataIndex : el.name,
                    },                   
                );
            });
            setColumns(merge(columnsBase,catColumnstemp,1));
        })
        .catch(error => {
   
        })
    }

    const getData = () =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/ltdfund`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

         
            let newData = [];

            response.data.forEach(el => {
                let row = {
                    date: el.date,
                    _id: el._id
                }
                el.fundSeries.forEach(val => {
        
                    row[val.fundSerie.name] = val.value;
                });

                newData.push(row);
            });

            setData(newData);
        })
        .catch(error => {
            
        })
    }

    const merge = (a, b, i=0) => {
        return a.slice(0, i).concat(b, a.slice(i));
    }

    const deleteRow = (id) => {
        axios({
            method:'DELETE',
            url: `${process.env.REACT_APP_API}/ltdfund/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            setData(data.filter((el) => el._id !== id ));
        })
        .catch(error => {
     
        })
    }

    const editRow = (record) => {
        setEdit(record);
    }

    const addRowLog = (log) => {
        setData([log, ...data]);
    }

    const table = () => (
        <Table columns={columns} dataSource={data}  rowKey="_id"/>
    );


    return (
    <Body>
        <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="Maquina I"
            subTitle="Fondo"
        />
        <Divider dashed />
        <AddFundLog addRowLog={addRowLog} edit={edit}/>
        {table()}   
    </Body>
    );
}

export default LTDFundLog;