import React, {useState, useEffect} from 'react';
import Body from '../../core/Body';
import axios from 'axios';
import { Form, PageHeader, Button, message, Input } from 'antd';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const AddInvertion = () => {

    const {auth} = useAuth();

    const {id: accountId} = useParams();

    const [form] = Form.useForm();
    const [values, setValues] = useState({
        user : auth.user._id,
        slug: '',
        name: '',
        buttonText: accountId ? 'Update' : 'Submit'
    });

    const token = auth.token;

    const [loading, setLoading] = useState(false);

    const {buttonText} = values;

    const clickSubmit = values =>  {
        
        let method = 'POST';
        let endpoint = `${process.env.REACT_APP_API}/account`;

        if(accountId){
            values['id'] = accountId;
            method = 'PUT';
            endpoint += '/' + accountId;
        }

        let btnTxt = accountId ? 'Updating..' : 'Submitting..';

        setValues({...values, buttonText: btnTxt});
        setLoading(true);

        axios({
            method:method,
            url: endpoint,
            data: values,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setLoading(false);
            setValues({...values, buttonText:'Submit'});
            message.success(`Has ${accountId ? 'actualizado' : 'creado'} una cuenta!`);
        })
        .catch(error => {
            message.error(`Error al ${accountId ? 'actualizar' : 'crear'} cuenta!`);
            setValues({...values, buttonText:accountId ? 'Update' : 'Submit'});
            setLoading(false);
        })
    }

    useEffect(() => {
        if(accountId){
            loadProfile()
        }
    }, [accountId]);

    const loadProfile = () => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API}/account/${accountId}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {

            const {name,slug} = response.data;
  
            setValues({...values, 
                slug:slug,
                name:name
            });
            form.resetFields();
        })
        .catch(error => {
    
        });
    }



    const layout = () => (
        <Form form={form} layout="vertical" initialValues={values} onFinish={clickSubmit}>

            <Form.Item label="Nombre" name="name">
                <Input placeholder="Nombre" />
            </Form.Item>
            <Form.Item label="Slug" name="slug">
                <Input placeholder="Slug" />
            </Form.Item>

            <Button loading={loading} type="primary" htmlType="submit">{buttonText}</Button>
        </Form>
    );

    return (
    <Body>
    <PageHeader
        className="site-page-header"
        onBack={() => console.log('go-back')}
        title="Nueva cuenta"
        subTitle="Cuenta"
    />
    {layout()}
        
    </Body>
    );
}

export default AddInvertion;