import React, { useState, useEffect } from "react";
import Body from "../core/Body";
import axios from "axios";
import jwt from "jsonwebtoken";
import { message } from "antd";
import { useParams } from "react-router-dom";
const Activate = () => {
    let { token } = useParams();

    const [values, setValues] = useState({
        name: "",
        tokenInput: "",
        show: true,
    });

    const { name } = values;

    useEffect(() => {
        let { name } = jwt.decode(token);

        if (token) {
            setValues({ ...values, name, tokenInput: token });
        }
    }, []);

    const clickSubmit = (event) => {
        event.preventDefault();

        setValues({ ...values, buttonText: "Submitting.." });
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/activate`,
            data: { token },
        })
            .then((response) => {
                setValues({ ...values, show: false });
                message.success(response.data.message);
            })
            .catch((error) => {
                message.error(error.response.data.error);
            });
    };

    const activationLink = () => (
        <div>
            <h1 className="p-5 text-center">
                Hey {name}, ready to activate your account?
            </h1>
            <button className="btn btn-outline-primary" onClick={clickSubmit}>
                Activate account
            </button>
        </div>
    );

    return (
        <Body>
            <div className="col-md-6 offset-md-3 text-center">
                {activationLink()}
            </div>
        </Body>
    );
};

export default Activate;
