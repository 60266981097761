import React, { useState, useEffect } from "react";
import Body from "../../core/Body";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import {
    Row,
    Col,
    Typography,
    Button,
    Statistic,
    PageHeader,
    Tabs,
} from "antd";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;
const { Title } = Typography;

const SubscriberDashboard = () => {
    const { auth } = useAuth();

    const token = auth.token;

    const [loading, setLoading] = useState(true);

    const [stats, setStats] = useState({
        details: [],
    });

    useEffect(() => {
        getStats();
    }, []);

    const getStats = () => {
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/invertion/stats/${auth.user._id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setStats(response.data);
                setLoading(false);
            })
            .catch((error) => {});
    };

    const content = () => (
        <>
            {!loading && (
                <>
                    <Statistic
                        style={{ padding: "24px 48px", background: "#f0f0f0" }}
                        title="Total"
                        value={stats.total}
                        precision={2}
                        prefix="$"
                        className="total-statistic"
                    />
                    <Tabs
                        defaultActiveKey="1"
                        className="home-tabs"
                        tabBarStyle={{
                            background: "rgb(248, 248, 248)",
                            borderTop: "1px solid #e5e5e5",
                            borderBottom: "1px solid #e5e5e5",
                        }}
                        style={{ paddingBottom: "4rem" }}
                    >
                        {stats.details[0].accountID && (
                            <TabPane
                                style={{ marginTop: "20px" }}
                                tab={stats.details[0].label}
                                key="1"
                            >
                                <Row
                                    align="middle"
                                    style={{
                                        padding: "2rem 0",
                                        boxShadow:
                                            "0 6px 6px rgba(43,43,43,.1)",
                                    }}
                                >
                                    <Col xl={12} xs={24} align="middle">
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[0].accountID}`}
                                        >
                                            <Button
                                                type="secondary"
                                                size="large"
                                                className="btn-gray mb-mobile"
                                            >
                                                Valor de tus inversiones
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col xl={12} xs={24} align="middle">
                                        <Statistic
                                            prefix="$"
                                            value={stats.details[0].valuation}
                                            precision={2}
                                            valueStyle={{
                                                color: "#005e87",
                                                fontSize: "2rem",
                                            }}
                                        />
                                        <div
                                            style={{
                                                color: "#000",
                                                fontSize: "13px",
                                            }}
                                        >
                                            Actualizado al{" "}
                                            <Moment format="DD/MM/YYYY">
                                                {stats.date}
                                            </Moment>
                                        </div>
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[0].accountID}`}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ margin: "20px 0" }}
                                            >
                                                Ver detalles
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>

                                <Row
                                    style={{ padding: "1rem 0 4rem 0" }}
                                    className="maquina-stats-row"
                                >
                                    <Col xl={12} xs={12} align="middle">
                                        <Title className="bb-gray" level={4}>
                                            Invertiste
                                        </Title>
                                        <Statistic
                                            style={{ marginTop: "1rem" }}
                                            prefix="$"
                                            value={stats.details[0].subscribed}
                                            precision={2}
                                            valueStyle={{
                                                color: "#999",
                                                fontSize: "1.8rem",
                                            }}
                                        />
                                    </Col>
                                    <Col xl={12} xs={12} align="middle">
                                        <Title className="bb-gray" level={4}>
                                            Variación
                                        </Title>
                                        <Statistic
                                            style={{ marginTop: "1rem" }}
                                            prefix="$"
                                            value={stats.details[0].profit}
                                            precision={2}
                                            valueStyle={{
                                                color: "#00aed8",
                                                fontSize: "1.8rem",
                                            }}
                                        />
                                        <div
                                            style={{
                                                fontSize: "1.4rem",
                                                color: "#036088",
                                            }}
                                        >
                                            (+{stats.details[0].variation}%)
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        )}

                        {stats.details[1].accountID && (
                            <TabPane
                                style={{ marginTop: "20px" }}
                                tab={stats.details[1].label}
                                key="2"
                            >
                                <Row align="middle">
                                    <Col xl={12} xs={24} align="middle">
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[1].accountID}`}
                                        >
                                            <Button
                                                type="secondary"
                                                size="large"
                                                className="btn-gray mb-mobile"
                                            >
                                                Valor de tus inversiones
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col xl={12} xs={24} align="middle">
                                        <Statistic
                                            prefix="$"
                                            value={stats.details[1].valuation}
                                            precision={2}
                                            valueStyle={{
                                                color: "#005e87",
                                                fontSize: "2rem",
                                            }}
                                        />
                                        <div
                                            style={{
                                                color: "#000",
                                                fontSize: "13px",
                                            }}
                                        >
                                            Actualizado al{" "}
                                            <Moment format="DD/MM/YYYY">
                                                {stats.date}
                                            </Moment>
                                        </div>
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[1].accountID}`}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ margin: "20px 0" }}
                                            >
                                                Ver detalles
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row
                                    align="middle"
                                    style={{
                                        padding: ".5rem 0",
                                        borderTop: "1px solid #ccc",
                                        borderBottom: "1px solid #ccc",
                                    }}
                                >
                                    <Col xl={12} xs={24} align="middle">
                                        <Title level={4}>Cantidad de BTC</Title>
                                    </Col>
                                    <Col xl={12} xs={24} align="middle">
                                        <Statistic
                                            value={stats.details[1].quantity}
                                            precision={7}
                                            valueStyle={{ color: "#333" }}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        )}

                        {stats.details[2].accountID && (
                            <TabPane
                                style={{ marginTop: "20px" }}
                                tab={stats.details[2].label}
                                key="3"
                            >
                                <Row align="middle">
                                    <Col xl={12} xs={24} align="middle">
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[2].accountID}`}
                                        >
                                            <Button
                                                type="secondary"
                                                size="large"
                                                className="btn-gray mb-mobile"
                                            >
                                                Valor de tus inversiones
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col xl={12} xs={24} align="middle">
                                        <Statistic
                                            prefix="$"
                                            value={stats.details[2].valuation}
                                            precision={2}
                                            valueStyle={{
                                                color: "#005e87",
                                                fontSize: "2rem",
                                            }}
                                        />
                                        <div
                                            style={{
                                                color: "#000",
                                                fontSize: "13px",
                                            }}
                                        >
                                            Actualizado al{" "}
                                            <Moment format="DD/MM/YYYY">
                                                {stats.date}
                                            </Moment>
                                        </div>
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[2].accountID}`}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ margin: "20px 0" }}
                                            >
                                                Ver detalles
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row
                                    align="middle"
                                    style={{
                                        padding: ".5rem 0",
                                        borderTop: "1px solid #ccc",
                                        borderBottom: "1px solid #ccc",
                                    }}
                                >
                                    <Col xl={12} xs={24} align="middle">
                                        <Title level={4}>Depositaste</Title>
                                    </Col>
                                    <Col xl={12} xs={24} align="middle">
                                        <Statistic
                                            value={stats.details[2].quantity}
                                            precision={7}
                                            valueStyle={{ color: "#333" }}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        )}

                        {stats.details[3].accountID && (
                            <TabPane
                                style={{ marginTop: "20px" }}
                                tab={stats.details[3].label}
                                key="4"
                            >
                                <Row align="middle">
                                    <Col xl={12} xs={24} align="middle">
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[3].accountID}`}
                                        >
                                            <Button
                                                type="secondary"
                                                size="large"
                                                className="btn-gray mb-mobile"
                                            >
                                                Valor de tus inversiones
                                            </Button>
                                        </Link>
                                    </Col>
                                    <Col xl={12} xs={24} align="middle">
                                        <Statistic
                                            prefix="$"
                                            value={stats.details[3].quantity}
                                            precision={2}
                                            valueStyle={{
                                                color: "#005e87",
                                                fontSize: "2rem",
                                            }}
                                        />
                                        <div
                                            style={{
                                                color: "#000",
                                                fontSize: "13px",
                                            }}
                                        >
                                            Actualizado al{" "}
                                            <Moment format="DD/MM/YYYY">
                                                {stats.details[3].date}
                                            </Moment>
                                        </div>
                                        <Link
                                            to={`/private/detail/${auth.user._id}/invertion/list/${stats.details[3].accountID}`}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ margin: "20px 0" }}
                                            >
                                                Ver detalles
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                    </Tabs>
                </>
            )}
        </>
    );

    return (
        <Body navClass="with-border">
            <PageHeader
                className="site-page-header dash-page-header"
                title="Resumen"
                subTitle="Información General"
            />
            {content()}
        </Body>
    );
};

export default SubscriberDashboard;
