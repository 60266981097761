import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { Input, Button, Row, Col, Typography } from 'antd';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import useAuth from '../../hooks/useAuth';
import Body from '../../core/Body';


const { Title } = Typography;

const MyCustomTooltip = (props) => {  
    if(props.payload != undefined && props.payload.length > 0) {
        const payload = props.payload[0].payload;
        return   (<div className="chart-tooltip" key={payload.index}>
                <p>Index: {payload.index}</p>
                <p>Date: {payload.date}</p>
                <p>Total Fee: {payload.value}</p>
                <p>Accum: {payload.accum}</p>
                {
                    payload.data.length > 0 && payload.data.map((el, index) => {
                        return (<div key={el.invertion._id +'-'+ index}>{el.user.name} - Fee: {el.fee} 
                        <Link to={`/admin/movement/invertion/${el.user._id}/${el.invertion._id}/${el.invertion.account._id}`}> Ver</Link></div>)
                    })
                }
            </div>
        ) 
    }else{
        return <></>
    }
}
const Estimate = () => {

    const {auth} = useAuth()
    
    const [performance, setPerformance] = useState([]);
    const [managment, setManagment] = useState([]);

    const token = auth.token;

    const [values,setValues] = useState({
        share: 0
    })

    useEffect(() => {
        getData();
    },[token]);

    const getData = (shareValue) =>  {

        axios({
            method:'GET',
            url: `${process.env.REACT_APP_API}/proyection/${values.share}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
                setPerformance(response.data.performanceProyection);
                setManagment(response.data.managmentProyection);
            
        })
        .catch(error => {
          
        })
    }

    const shareChange = e => {
        setValues({...values, share: e.target.value});
    }

    return (
    <Body>
        <Input addonBefore="Share value:" placeholder="Share Value" onChange={shareChange} style={{width:300}} />
        <Button type="primary" style={{marginLeft:20}} onClick={() => getData(values.share)}>Filter</Button>
        <Row>
            <Col span={12} style={{height:500, marginTop:50}}>
            <Title level={4}>Performance Fee</Title>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={performance} width={500} height={500}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis />
                <Tooltip trigger="click" wrapperStyle={{pointerEvents:'all'}} content={MyCustomTooltip} />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
            </Col>
            <Col span={12} style={{height:500, marginTop:50}}>
            <Title level={4}>Performance Fee Acumulado</Title>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={performance} width={500} height={500}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis />
                <Tooltip trigger="click" wrapperStyle={{pointerEvents:'all'}} content={MyCustomTooltip} />
                <Legend />
                <Bar dataKey="accum" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
            </Col>
        </Row>
        <Row>
            <Col span={12} style={{height:500, marginTop:50}}>
            <Title level={4}>Managment Fee</Title>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={managment} width={500} height={500}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis />
                <Tooltip trigger="click" wrapperStyle={{pointerEvents:'all'}} content={MyCustomTooltip} />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
            </Col>
            <Col span={12} style={{height:500, marginTop:50}}>
            <Title level={4}>Managment Fee Acumulado</Title>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={managment} width={500} height={500}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" />
                <YAxis />
                <Tooltip trigger="click" wrapperStyle={{pointerEvents:'all'}} content={MyCustomTooltip} />
                <Legend />
                <Bar dataKey="accum" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
            </Col>
        </Row>
   </Body>
    );
}

export default Estimate;

