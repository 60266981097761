import React, {useState, useEffect} from 'react';
import IdleTimer from './IdleTimer';
import {signout} from '../auth/helpers';
import { MenuOutlined,CloseOutlined } from '@ant-design/icons';
import { Layout, Menu, Button} from 'antd';
import axios from 'axios';
import logo from '../assets/images/logoblack.png';
import {
  MobileView,
} from "react-device-detect";
import { withRouter } from './withRouter';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
const { Content, Footer, Sider } = Layout;


const Body = ({children, location, navClass}) => {

    const navigate = useNavigate();

    const [timer, setTimer] = useState();

    const {auth, setAuth} = useAuth();
    
    const token = auth?.token || '';

    const [items, setItems] = useState([]);

    const [openKeys, setOpenKeys] = useState([]);

    const [lastOpen, setLastOpen] = useState('');

    const [mobDisplay,setMobDisplay] = useState('none');

    const rootKeys = ['/private/detail/'];

    useEffect(() => {

      const idle = new IdleTimer({
        timeout: 60 * 30, // 30min
        onTimeout: () => {
          signout( () => {
            setAuth('');
            navigate('/');
          }, this);
        },
        onExpired: () => {
          signout(() => {
            setAuth('');
            navigate('/');
          }, this);
        }
      });

      setTimer(idle);
  
      return () => {
        idle.cleanUp();
      };
    }, []);

    const getAccounts = () => {
      axios({
          method:'GET',
          url: `${process.env.REACT_APP_API}/account`,
          headers: {
              Authorization: `Bearer ${token}`
          }
      })
      .then(response => {

          response.data.forEach( el => {
            clientMenuItem[1].children.push({ label: el.name, key: `/private/detail/${auth.user._id}/invertion/list/${el._id}` })
          })
          setMenu();
      })
      .catch(error => {
        console.log(error);
      })       
    };

    useEffect(()=>{
      if(auth && auth?.user?.role !== 'admin'){
        getAccounts();
      }
    },[token]);


    const authMenuItem = [
        { label: 'Ingresar', key:'/'},
        { label: 'Registrarse', key:'redirect'}
    ];

    const accountMenuItem = [
      { label: 'Mi Perfil', key:"/private/account"},
      { label: 'Cerrar Sesión', key:"logout" }
    ];

    const adminMenuItem = [
      { label: 'Dashboard', key:'/admin/dashboard'},
      {
        label:'Clientes', key:'keyclientes',
        children: [
          {
            label:'Usuarios',
            key:'sub5',
            children: [
              {label:'Ver todos',key:"/admin/user/list"},
              {label:'Nuevo Usuario', key:"/admin/user/add"},
              {label:'Deshabilitar', key:"/admin/user/disable"}
            ]
          },
          { 
            label: 'Inversiones',
            key:"sub0", 
            children: [
              { label: 'Ver todas', key: "/admin/invertion/list" },
              { label: 'Nueva Inversión', key: "/admin/invertion/add" }
            ]
          },
          {
            label:'Cuentas',
            key:'sub3',
            children: [
              {label: 'Ver todas', key:"/admin/account/list"},
              {label: 'Nueva Cuenta', key:"/admin/account/add"}
            ]
          },
          { label: 'Cron Jobs', key: '/admin/cron'},
          { label: 'Notificaciones', key:`/private/notifications/${auth.user._id}`},
        ]
      },
      {
        label: 'Funds', key:'keyfunds',
        children: [
          { label: 'Historico', key: '/admin/maquina'},
          { 
            label: 'Historico LTD', 
            key: '/admin/ltd-parent',
            children: [
            {label:'Historico', key:'/admin/ltd'},
            {label:'Listado Series', key:'/admin/series/list'},
            {label: 'Agregar Serie', key:'/admin/series/add'},
          ]},
          {
            label:'Portfolio',
            key:'sub1',
            children: [
              {label:'Listado', key:'/admin/portfolio'},
              {label: 'Categorias', key:'/admin/portfolio/list'},
              {label: 'Agregar Categoria', key:'/admin/portfolio/add'},
            ]
          },
          {
            label: 'Coingecko',
            key:'sub2',
            children: [
              {label: 'Coins', key:"/admin/coingecko/list"},
              {label: 'Valores', key:"/admin/coingecko/table"}
            ]
          },
          {
            label:'Operaciones',
            key:'sub4',
            children:[
              {label:'Ver todas', key:"/admin/operation/list" },
              {label: 'Nueva Operación', key:"/admin/operation/add" }
            ]
          },
        ]
      },
      { label: 'Proyecciones', key: '/admin/proyection'}
  ];

    
    let clientMenuItem = [
      { label: 'Resumen', key:'/private/dashboard'},
      { 
        label: 'Detalles',
        key:'/private/detail/', 
        children: [ 
        { 
          label: 'Detalle de cuenta', 
          key:'/private/detail/account-detail'}
        ]
      },
      { 
        label: 'Maquina I',
        key:'sub6',
        children: [
        { label: 'Unit', key:`/private/unit`},
        { label: 'Portfolio', key:`/private/chartportfolio`},
        { label: 'Rendimientos', key:"/private/rendimiento"}
        ]
      },
      { 
        label: 'Informes',
        key: "sub7" , 
        children: [
            { label: 'Cryptonews', key: "/private/blog/cryptonews"},
            { label: 'Informes', key: "/private/blog/informes"}
          ]
      }

  ];

    const handleClick = e => {

      if(e.key !== "redirect"){
        if(e.key !== 'logout'){

          navigate(e.key);

          if(e.item.props.subMenuKey){
            let ret = e.item.props.subMenuKey.replace('-menu-','');
            const findKey = rootKeys.find(el => el === ret);
  
            if(findKey){
              setOpenKeys([ret]);
              return;
            }
          }
  
          setOpenKeys(lastOpen);
          
  
        }else{
          signout(() => {
            setAuth('');
            navigate('/');
          }, timer);
        }
      }else{
        window.location.href = "https://maquinacapital.com/datospersonales";
      }

    };


    const onOpenChange = items => {

      const latestOpenKey = items.find(key => openKeys.indexOf(key) === -1);
      if(items){
        setLastOpen(items)
      }
      if (rootKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(items);
      }  else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : 'defaultOpenKeys');
      }
    };

    const toggleMobMenu = () => {
      if(mobDisplay === 'block'){
        setMobDisplay('none');
      }else{
        setMobDisplay('block');
      }
    }

    const setMenu = () => {
      let items = [];
      if(auth?.user){

        if(auth?.user?.role === 'admin' ){
          items = adminMenuItem.concat(accountMenuItem);
        }else if(auth?.user?.role !== 'admin'){
          items = clientMenuItem.concat(accountMenuItem);
        }else{
          items = accountMenuItem
        }
      }else{
        items =  authMenuItem;
      }

      setItems(items);
    };

    useEffect(() => {
      setMenu();
    },[]);
    

    const newNav = () => (
      <Menu
        onSelect={handleClick}
        theme="light"
        mode="inline"
        style={{backgroundColor: '#f0f0f0'}}
        activeKey={[location.pathname]}
        onOpenChange={onOpenChange}
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        items={items}
      />
    );

    
    return (
      <Layout style={{ minHeight: '100vh' }}>
        {auth.user && (
        <Sider theme="light" 
        className="sider-global">
          <img src={logo} style={{width:'40%','margin':'2rem auto','display':'block'}} alt="logo"/>
          {newNav()}
        </Sider>)}
        <Layout className="site-layout">
            <MobileView>
              <nav className={navClass ? 'mobile-nav ' + navClass : 'mobile-nav with-shadow'}>
                <img src={logo} style={{width:'40px','display':'block','marginLeft':'2rem'}} alt="logo"/>
                <Button className={mobDisplay === 'block' ? "btn-burger active" : "btn-burger"} onClick={toggleMobMenu}>{mobDisplay === 'block' ?
                <CloseOutlined style={{color:'#fff','fontSize':'30px','lineHeight':'90%'}}/> : <MenuOutlined style={{color:'#000','fontSize':'30px','lineHeight':'90%'}}/>}
                </Button>
              </nav>
              <div className="mobile-menu-wrap" style={{'display':mobDisplay}}>
                {newNav()}
              </div>
            </MobileView>
            <Content className={`site-layout-content ${auth && auth?.user?.role === 'admin' ? "site-layout-content-admin" : "site-layout-content-subscriber"}`}>
              {children}
            </Content>
            <Footer style={{ textAlign: 'center',padding:'.5rem 0' }}>Maquina Capital © 2021</Footer>
        </Layout>
      </Layout>
     )
}

export default withRouter(Body);