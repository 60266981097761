import React, { useState, useEffect } from "react";
import Body from "../../core/Body";
import axios from "axios";
import { signout } from "../../auth/helpers";
import {
    Input,
    Skeleton,
    PageHeader,
    Form,
    Select,
    message,
    Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
const { Option } = Select;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const AddEditUser = () => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const { id: userId } = useParams();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [initialValues, setInitialValues] = useState({});

    const [buttonText, setButtonText] = useState("Guardar usuario");

    const token = auth.token;

    useEffect(() => {
        if (userId) {
            loadProfile();
        }
    }, [userId]);

    const loadProfile = () => {
        setLoading(true);
        axios({
            method: "GET",
            url: `${process.env.REACT_APP_API}/user/${userId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                setInitialValues(response.data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    signout(() => {
                        setAuth("");
                        navigate("/");
                    }, "");
                }
            });
    };

    const handleSubmit = (values) => {
        setButtonText("Submitting..");
        let endpoint = userId ? "edit" : "add";
        let method = userId ? "PUT" : "POST";
        if (userId) {
            values["_id"] = userId;
        }
        axios({
            method: method,
            url: `${process.env.REACT_APP_API}/user/${endpoint}`,
            data: values,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                message.success("Usuario guardado exitosamente.");
                setButtonText("Guardar usuario");
            })
            .catch((error) => {
                message.error("Error!");
                setButtonText("Guardar usuario");
            });
    };

    const addUserForm = () => (
        <Form
            {...layout}
            name={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
            layout="vertical"
        >
            <h3>Datos del titular</h3>
            <Form.Item label="Alias" name="name">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Nombre" name="firstname">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Apellido" name="lastname">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="DNI" name="dni">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Telefono" name="phone">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Fecha de nacimiento" name="birthday">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Direccion" name="address">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Email" name="email">
                <Input type="email" autoComplete="new-email" />
            </Form.Item>

            <h3>Datos del Co-Titular</h3>
            <Form.Item label="Nombre - Co-Titular" name="co_firstname">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Apellido - Co-Titular" name="co_lastname">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="DNI - Co-Titular" name="co_dni">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Telefono - Co-Titular" name="co_phone">
                <Input type="text" />
            </Form.Item>
            <Form.Item
                label="Fecha de nacimiento - Co-Titular"
                name="co_birthday"
            >
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Direccion - Co-Titular" name="co_address">
                <Input type="text" />
            </Form.Item>
            <Form.Item label="Email - Co-Titular" name="co_email">
                <Input type="email" autoComplete="new-email" />
            </Form.Item>

            <h3>Datos de la cuenta</h3>
            <Form.Item label="Contraseña" name="password">
                <Input type="password" autoComplete="new-password" />
            </Form.Item>
            <Form.Item label="Rol" name="role">
                <Select>
                    <Option value="guest">Invitado</Option>
                    <Option value="subscriber">Inversor</Option>
                    <Option value="admin">Admin</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Estado" name="status">
                <Select>
                    <Option value="active">Active</Option>
                    <Option value="inactive">Inactive</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">{buttonText}</Button>
            </Form.Item>
        </Form>
    );

    return (
        <Body>
            <div>
                <PageHeader
                    className="site-page-header"
                    onBack={() => console.log("go-back")}
                    title={"Agregar/Editar usuario"}
                    subTitle="Usuarios"
                />
                <Skeleton loading={loading} active />
                {!loading && addUserForm()}
            </div>
        </Body>
    );
};

export default AddEditUser;
