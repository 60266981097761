import React, { useState } from "react";
import axios from "axios";
import { Form, message, Button, Input } from "antd";

const ValidateOTP = ({ id, callback }) => {
    const [form] = Form.useForm();

    const [values, setValues] = useState({
        id: id,
        code: "",
        buttonText: "Validar",
    });

    const { buttonText } = values;

    const handleSubmit = (values) => {
        setValues({ ...values, buttonText: "Validando.." });

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/otp/validate`,
            data: values,
        })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                message.error(error.response.data.error);
                setValues({ ...values, buttonText: "Validar" });
            });
    };

    const validateCodeForm = () => (
        <div>
            <h3>Ingresa tu código de 2F:</h3>
            <Form
                form={form}
                layout="vertical"
                initialValues={values}
                onFinish={handleSubmit}
            >
                <Form.Item name="id" label="Id" hidden>
                    <Input readOnly />
                </Form.Item>
                <Form.Item name="code" label="Código">
                    <Input autoComplete="off" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {buttonText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    return <>{validateCodeForm()}</>;
};

export default ValidateOTP;
