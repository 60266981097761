export function ReferenceLabel(props) {

    const { 
        fill, value, textAnchor, 
        fontSize, viewBox, dy, dx, position
    } = props;
    const x = viewBox.width + 20;
    const y = viewBox.y + 5;

    return (
        <g>
            <defs>
                <filter id="rounded-corners" x="-5%" width="110%" y="0%" height="100%">
                    <feFlood floodColor="rgb(41, 88, 118)"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComponentTransfer>
                    <feFuncA type="table" tableValues="0 0 0 1"/>
                    </feComponentTransfer>

                    <feComponentTransfer>
                    <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1"/>
                    </feComponentTransfer>
                    <feComposite operator="over" in="SourceGraphic"/>
                </filter>
   
            </defs>
            <text 
                x={x} y={y}
                position={position}
                dy={dy}
                filter="url(#rounded-corners)"
                dx={dx}
                fill={fill}
                fontSize={fontSize || 16} 
                textAnchor={textAnchor}>
                {value}
            </text>
        </g>
    )
}